import React from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { updateProfile, viewOrders } from './actions';
import { TextField, Button } from '@mui/material';
import { css } from '@emotion/react';

const CustomerControlPanel = () => {
  // const dispatch = useDispatch();
  const profile = {
    name: 'John Doe',
    email: 'johndoe@example.com',
    address: '123 Main St, City, Country',
  };//useSelector((state) => state.profile);

  const handleUpdateProfile = () => {
    // const updatedProfileData = {
    //   name: 'Updated Name',
    //   email: 'updatedemail@example.com',
    //   address: 'Updated Address',
    // };
    // dispatch(updateProfile(updatedProfileData));
  };

  const handleViewOrders = () => {
    // dispatch(viewOrders());
  };

  return (
    <div css={container}>
      <h2>Customer Control Panel</h2>
      <div css={section}>
        <h3>Profile</h3>
        <TextField
          label="Name"
          value={profile.name}
          variant="outlined"
          fullWidth
        />
        <TextField
          label="Email"
          value={profile.email}
          variant="outlined"
          fullWidth
        />
        <TextField
          label="Address"
          value={profile.address}
            variant="outlined"
            fullWidth
          />
          <Button variant="contained" color="primary" onClick={handleUpdateProfile}>
            Update Profile
          </Button>
        </div>
        <div css={section}>
          <h3>Order History</h3>
          <Button variant="contained" color="primary" onClick={handleViewOrders}>
            View Orders
          </Button>
          {/* Render order history here */}
        </div>
      </div>
    );
  };
  
  // Emotion CSS styles
  const container = css`
    max-width: 600px;
    margin: auto;
    padding: 20px;
  `;
  
  const section = css`
    margin-bottom: 20px;
  `;
  
  export default CustomerControlPanel;
  