/* (--- AUTO GEN ---) */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from 'react-bootstrap';
/* eslint-enable no-unused-vars */

export default function CommunityInvitesToMeTableRow({
  communityInviteToMe,
  acceptClick,
  declineClick,
}) {
  function doAccept() {
    acceptClick(communityInviteToMe);
  }

  function doDecline() {
    declineClick(communityInviteToMe);
  }

  return (
    <tr>
      <td>
        <h3>{communityInviteToMe.displayNameInvitingUser}</h3>
      </td>
      <td align="center">
        <Button variant="primary" onClick={doAccept}>
          Accept
        </Button>
      </td>
      <td align="center">
        <Button variant="danger" onClick={doDecline}>
          Decline
        </Button>
      </td>
    </tr>
  );
}

CommunityInvitesToMeTableRow.propTypes = {
  communityInviteToMe: PropTypes.object.isRequired,
};
