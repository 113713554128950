import React from 'react';

import { Alert, Tooltip } from '@mui/material';

const typeArray = ['Dressage', 'Show Jumping', 'XC'];

function tipText(test) {
  return (
    'Name: ' +
    test.name +
    '\r\n' +
    'Type: ' +
    typeArray[test.type] +
    '\r\n' +
    'Level: ' +
    test.eventLevelName +
    '\r\n' +
    'Minutes: ' +
    test.minutes
  );
}


// THE FOLLOWING IS NEEDED to be sure the Tooltip text CRLFs.  No idea why. 

// title={
//     <span style={{ whiteSpace: 'pre-line' }}>{tipText(testData)}</span>
//   }


const EventTest = ({ testData }) => {
  return (
    <Tooltip
      title={
        <span style={{ whiteSpace: 'pre-line' }}>{tipText(testData)}</span>
      }
      placement="left"
    >
      <Alert severity="success" onClose={() => {}}>
        {testData.name}
      </Alert>
    </Tooltip>
  );
};

export default EventTest;
