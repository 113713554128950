/* (--- AUTO GEN ---) */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import ModalImage from 'react-modal-image';
/* eslint-enable no-unused-vars */

export default function CommunityUsersTableRow({ communityUser }) {
  function renderImgTag(photoURL) {
    if (photoURL && photoURL.length > 0) {
      return (
        <ModalImage
          small={photoURL}
          medium={photoURL}
          large={photoURL}
          alt="Hello World!"
          key={photoURL}
        />
      );
    } else {
      return (
        <Image src="/images/photos/blank.png" alt="Avatar" rounded responsive />
      );
    }
  }

  function getPhotoColumn() {
    if (
      communityUser &&
      communityUser.photoURL &&
      communityUser.photoURL.length > 0
    ) {
      return (
        <div>
          <div>{renderImgTag(communityUser.photoURL)}</div>
        </div>
      );
    }
    return (
      <div>
        <div>
          <Image
            src="/images/object-avatar-100px.jpg"
            alt="Avatar"
            rounded
            responsive
          />
        </div>
      </div>
    );
  }

  return (
    <tr>
      <td>
        <h3>{getPhotoColumn()}</h3>
      </td>
      <td>
        <h3>{communityUser.displayNameCommunityUser}</h3>
      </td>
    </tr>
  );
}

CommunityUsersTableRow.propTypes = {
  communityUser: PropTypes.object.isRequired,
};
