/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import classnames from 'classnames';
import {
  textArea,
  intSelector,
  photoUploader,
} from '../../../core/CoreForms';
import { Col, Card, Form, Button, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import _ from 'lodash';
/* eslint-enable no-unused-vars */

class MediaForm extends Component {
  state = {
    id: this.props.media ? this.props.media.id : null,
    content: this.props.media ? this.props.media.content : '',
    type: this.props.media ? this.props.media.type : 0,
    errors: '',
    loading: false,
    addPhoto: false,
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.media) {
      this.setState({
        id: nextProps.media ? nextProps.media.id : null,
        content: nextProps.media.content,
        type: nextProps.media.type,
      });
    }
  };

  handleChange = (e) => {
    if (e.target) {
      if (!!this.state.errors[e.target.id]) {
        //  gets a separate copy of errors
        let errors = Object.assign({}, this.state.errors);
        delete errors[e.target.id];
        this.setState({
          [e.target.id]: e.target.value,
          errors,
        });
      } else {
        this.setState({ [e.target.id]: e.target.value });
      }
    }
  };

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    // validation
    let errors = {};
    this.setState({ errors });
    const isValid = Object.keys(errors).length === 0;

    if (isValid) {
      const { id, content, type } = this.state;
      this.setState({ loading: true });
      this.props
        .saveMedia({
          id,
          content,
          type,
        })
        .catch((err) =>
          err.response
            .json()
            .then(({ errors }) => this.setState({ errors, loading: false }))
        );
    }
  };

  handleBoolChanged = (e) => {
    this.setState({ [e.target.id]: e.target.checked });
    if (e.target.id === 'oneDay') {
      if (e.target.checked) {
        this.setState({ endDate: this.state.startDate });
      }
    }
  };

  handleSelectChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  renderFormTitle() {
    if (this.state.id && this.state.id > 0) {
      return (
        <div>
          <h1>Edit Media</h1>
        </div>
      );
    } else {
      return (
        <div>
          <h1>Add Media</h1>
        </div>
      );
    }
  }

  cancelButton() {
    return <Button variant="warning">Cancel</Button>;
  }

  saveButton() {
    if (!this.state.addPhoto) {
      return (
        <Button type="submit" variant="primary">
          Save
        </Button>
      );
    }
  }

  deletePostClick = () => {
    if (this.props.deleteMediaCall && this.state.id) {
      this.props.deleteMediaCall({ id: this.state.id });
    }
  };

  deleteButton() {
    if (this.state.id && this.state.id > 0) {
      return (
        <Button onClick={this.deletePostClick} variant="danger" >
          Delete
        </Button>
      );
    }
  }

  addPhotoClick = () => {
    this.setState({
      addPhoto: !this.state.addPhoto,
    });
  };

  onCancelPhotoClick = () => {
    this.setState({
      addPhoto: false,
    });
  };

  addPhotoButton() {
    if (!(this.state.id && this.state.id > 0)) {
      if (!this.state.addPhoto) {
        return (
          <Button onClick={this.addPhotoClick} variant="primary">
            Add Photo
          </Button>
        );
      }
    }
  }

  okCancelButtons() {
    return (
      <ButtonToolbar>
        <LinkContainer to={'/media'}>{this.cancelButton()}</LinkContainer>
        {this.saveButton()}
        {this.addPhotoButton()}
        {this.deleteButton()}
      </ButtonToolbar>
    );
  }

  showPhotoForm(photoForm) {
    if (this.state.addPhoto) {
      return <Card.Body>{photoForm}</Card.Body>;
    }
  }

  handleSubmitPhoto = (e) => {
    if (e.target.photos.files.length > 0) {
      if (e) {
        e.preventDefault();
      }

      const { content, type } = this.state;
      const data = new FormData();
      data.append('content', content);
      data.append('type', type);

      for (
        let i = 0, numFiles = e.target.photos.files.length;
        i < numFiles;
        i++
      ) {
        let file = e.target.photos.files[i];
        data.append('photos', file, file.name);
      }
      this.setState({ photoDescription: '', loading: true });
      e.target.photos.value = null; // blanks the file
      this.props
        .addMediaWithPhotosCall(data);
    }
  };

  /* eslint-disable max-len */
  render() {
    const photoForm = (
      <Card variant="danger">
        <Card.Header>Add Photo</Card.Header>
        <Card.Body>
          <Form
            className={classnames('ui', 'form', {
              loading: this.state.loading,
            })}
            onSubmit={this.handleSubmitPhoto}
          >
            {photoUploader()}
            <ButtonToolbar>
              <Button onClick={this.onCancelPhotoClick} variant="warning">
                Cancel Photo
              </Button>
              <Button type="submit" variant="primary">
                Submit
              </Button>
            </ButtonToolbar>
          </Form>
        </Card.Body>
      </Card>
    );
    const typeList = ['General', 'Sales', 'Events'];
    const form = (
      <Form
        className={classnames('ui', 'form', { loading: this.state.loading })}
        onSubmit={this.handleSubmit}
      >
        {this.renderFormTitle()}
        {!!this.state.errors.global && (
          <div className="ui negative message">
            <p>{this.state.errors.global}</p>
          </div>
        )}
        {textArea('content', 'Content', this)}
        {intSelector('type', 'Type', this, typeList)}
        <div className="field">{this.okCancelButtons('/medias')}</div>
      </Form>
    );
    return (
      <Col xs={12} sm={12} md={12}>
        <Card variant="danger">
          <Card.Header></Card.Header>
          <Card.Body>{form}</Card.Body>
          {this.showPhotoForm(photoForm)}
        </Card>
      </Col>
    );
  }
}

export default (MediaForm);
