/* AUTO GEN */
/* Photo.js */

import _ from 'lodash';

export class Photo {
  constructor(
    id,
    userId,
    description,
    title,
    amazons3URL,
    amazons3URLHalf,
    amazons3URLThumb,
    originalName,
    type,
    dataStatus,
    createdAt,
    fullHeight,
    fullWidth,
    halfHeight,
    halfWidth,
    thumbHeight,
    thumbWidth,
    linkPhotoId
  ) {
    this.id = id;
    this.userId = userId;
    this.description = description;
    this.title = title;
    this.amazons3URL = amazons3URL;
    this.amazons3URLHalf = amazons3URLHalf;
    this.amazons3URLThumb = amazons3URLThumb;
    this.originalName = originalName;
    this.type = type;
    this.dataStatus = dataStatus;
    this.createdAt = createdAt;
    this.fullHeight = fullHeight;
    this.fullWidth = fullWidth;
    this.halfHeight = halfHeight;
    this.halfWidth = halfWidth;
    this.thumbHeight = thumbHeight;
    this.thumbWidth = thumbWidth;
    this.linkPhotoId = linkPhotoId;
  }
}

export function photoToClass(photo) {
  if (photo) {
    return new Photo(
      photo.id,
      photo.userId,
      photo.description,
      photo.title,
      photo.amazons3URL,
      photo.amazons3URLHalf,
      photo.amazons3URLThumb,
      photo.originalName,
      photo.type,
      photo.dataStatus,
      photo.createdAt,
      photo.fullHeight,
      photo.fullWidth,
      photo.halfHeight,
      photo.halfWidth,
      photo.thumbHeight,
      photo.thumbWidth,
      photo.linkPhotoId
    );
  } else {
    return null;
  }
}

export function photosToClassArray(photos) {
  if (photos) {
    const photosArray = photos.map((item) => {
      return photoToClass(item);
    });
    return photosArray;
  }
}

export function photosToOrderedArray(photos, order) {
  if (photos) {
    const photosArray = photosToClassArray(photos);
    if(photosArray) {
      if(order && photosArray.length === order.length) {
        var orderedArray = [];
        order.forEach((photoId) => {
          var photo = _.find(photosArray, { "id": photoId });
          orderedArray.push(photo);
        });
        return orderedArray;
      } else {
        return photosArray;
      }
    }
  }
}
