import axios from 'axios';
import { USERS_SEARCHED, USER_FETCHED } from '../types';
import {
  searchedUserToClass,
  searchedUsersToClassArray,
} from '../../classes/users/SearchedUser';

export function userFetched(searchedUser, timestamp) {
  return {
    type: USER_FETCHED,
    searchedUser: searchedUser,
    timestamp,
  };
}
export function clearUser(timestamp) {
  return {
    type: USER_FETCHED,
    searchedUser: {},
    timestamp,
  };
}

export function setUserSearchResults(searchedUsers, searchString, timestamp) {
  return {
    type: USERS_SEARCHED,
    searchedUsers: searchedUsersToClassArray(searchedUsers),
    searchString,
    timestamp,
  };
}
export function clearUserSearchResults(timestamp) {
  return {
    type: USERS_SEARCHED,
    searchedUsers: [],
    searchString: '',
    timestamp,
  };
}

export const searchUsersByEmailAndName = (nameOrEmail) => async (dispatch) => {
  const timestamp = new Date();
  if (nameOrEmail.length > 0) {
    const res = await axios.get(
      '/api/users/search/bynameoremail/' + nameOrEmail
    );
    dispatch(setUserSearchResults(res.data, nameOrEmail, timestamp));
  } else {
    dispatch(clearUserSearchResults(timestamp));
  }
};

export const getEditableUserData = (userId) => async (dispatch) => {
  const timestamp = new Date();
  if (userId > 0) {
    const res = await axios.get('/api/users/read/' + userId);
    dispatch(userFetched(res.data, timestamp));
  } else {
    dispatch(clearUser(timestamp));
  }
};

export const addUserPermission = (userId, permissionId) => async (dispatch) => {
  const timestamp = new Date();
  const res = await axios.post('/api/userpermissions/create', {
    userId,
    permissionId,
  });
  dispatch(userFetched(res.data, timestamp));
};

export const removeUserPermission = (userId, userPermissionId) => async (dispatch) => {
  const timestamp = new Date();
  const res = await axios.post('/api/userpermissions/delete', { userId, 
    userPermissionId,
  });
  dispatch(userFetched(res.data, timestamp));
};

export const addUserBarn = (userId, barnId) => async (dispatch) => {
  const timestamp = new Date();
  const res = await axios.post('/api/barnusers/create', {
    userId,
    barnId,
  });
  dispatch(userFetched(res.data, timestamp));
};

export const removeUserBarn = (userId, userBarnId) => async (dispatch) => {
  const timestamp = new Date();
  const res = await axios.post('/api/barnusers/delete', { userId, 
    userBarnId,
  });
  dispatch(userFetched(res.data, timestamp));
};
