import { SET_CALENDAR, CALENDAR_EVENT_FETCHED } from '../actions/types';

const INITIAL_STATE = [];

export default function reduce(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CALENDAR_EVENT_FETCHED:
      var itemFound = false;
      var newState = state.map((item) => {
        if (item.id === action.event.id) {
          // Item UPDATED
          itemFound = true;
          return action.event;
        } else return item;
      });
      if (!itemFound) {
        // Item CREATED
        newState.push(action.event);
      }
      return [...newState];
    case SET_CALENDAR:
      return action.events;
    default:
      return state;
  }
}
