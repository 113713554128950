import React from 'react';
import styled from '@emotion/styled';
import { Chip as MuiChip } from '@mui/material';

import { useDrag } from 'react-dnd';
import { ItemTypes } from '../Constants';

import { dateToTime } from '../../core/DateTools';

const Chip = styled(MuiChip)`
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;

  span {
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const DressageEntryChip = ({ entry, eventDivision }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.DRESSAGE_ENTRY,
    item: {
      eventDivision: eventDivision,
      entry: entry,
      type: ItemTypes.DRESSAGE_ENTRY,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  function timeToText(rideTimes) {
    if (rideTimes) {
      if (rideTimes.dressage) {
        var newTime = new Date(rideTimes.dressage.time);
        return dateToTime(newTime);
      } else return 'Unsc';
    } else return 'Unsc';
  }

  function getEntryItemLabel(entry) {
    return (
      entry.riderName +
      ' : ' +
      entry.horseName +
      '(' +
      timeToText(entry.rideTimes) +
      ')'
    );
  }

  const style = {
    opacity: isDragging ? 0.95 : 1,
    cursor: 'move',
  };

  return (
    <>
      {entry && (
        <Chip
          ref={drag}
          key={entry.id}
          label={getEntryItemLabel(entry)}
          color="error"
          style={style}
        />
      )}
    </>
  );
};

export default DressageEntryChip;
