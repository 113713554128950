/* AUTO GEN */
/* Jump.js */
import {
  photoToClass,
  photosToClassArray,
  photosToOrderedArray,
} from './Photo';
import { updateProperties } from '../actions/jumpActions';
import { jumpLogItemsToClassArray } from './JumpLogItem';

function propertiesTemplate() {
  return { photoOrder: [], videoOrder: [] };
}

export class Jump {
    constructor(
      id, 
      jumpNumber, 
      description, 
      category, 
      level, 
      jumpLevel, 
      type, 
      jumpCondition, 
      notes, 
      color, 
      skinny, 
      photoBin, 
      photoURL, 
      height, 
      width, 
      baseSpread, 
      photoId, 
      properties, 
      dataStatus, 
      createdAt, 
      updatedAt,
      photo,
      photos,
      logs
    ) {
      this.id = id;
      this.jumpNumber = jumpNumber;
      this.description = description;
      this.category = category;
      this.level = level;
      this.jumpLevel = jumpLevel;
      this.type = type;
      this.jumpCondition = jumpCondition;
      this.notes = notes;
      this.color = color;
      this.skinny = skinny;
      this.photoBin = photoBin;
      this.photoURL = photoURL;
      this.height = height;
      this.width = width;
      this.baseSpread = baseSpread;
      this.photoId = photoId;
      this.properties = properties;
      this.dataStatus = dataStatus;
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;

      if (photo) {
        this.photo = photoToClass(photo);
      }
      if (photos) {
        this.photos = photosToClassArray(photos);
      }
      if (logs) {
        this.logs = jumpLogItemsToClassArray(logs);
      }

      if (properties) {
        this.properties = properties;
        if (photos) {
          this.photos = photosToOrderedArray(photos, this.properties.photoOrder);
        }
      } else {
        this.properties = propertiesTemplate();
        this.fillPhotoOrder();
      }
    }

    fillPhotoOrder() {
      if (this.photos) {
        if (this.properties && this.properties.photoOrder) {
          this.photos.forEach((photo) => {
            this.properties.photoOrder.push(photo.id);
          });
        }
      }
    }

    hasPhoto() {
      return (this.photoURL && this.photoURL.length > 1) || this.photo;
    }

    getMainPhotoURL() {
      if (this.photo) {
        return this.photo.amazons3URL;
      }
      return this.photoURL;
    }

    getMainPhotoURLHalf() {
      if (this.photo) {
        return this.photo.amazons3URLHalf;
      } else if (this.photoURL) {
        this.photoURL.replace('thumb', 'half');
      }
    }

    onPhotoMoved(dispatch, photoOrder) {
      console.log('update data - ' + this.id);
      if (this.properties && this.properties.photoOrder) {
        this.properties.photoOrder = photoOrder;
        this.photos = photosToOrderedArray(
          this.photos,
          this.properties.photoOrder
        );
  
        var values = { id: this.id, properties: this.properties };
        dispatch(updateProperties(values));
      }
    }
    
  }
  
  export function jumpToClass(jump) {
    if (jump) {
      return new Jump(
        jump.id, 
        jump.jumpNumber, 
        jump.description, 
        jump.category, 
        jump.level, 
        jump.jumpLevel, 
        jump.type, 
        jump.jumpCondition, 
        jump.notes, 
        jump.color, 
        jump.skinny, 
        jump.photoBin, 
        jump.photoURL, 
        jump.height, 
        jump.width, 
        jump.baseSpread, 
        jump.photoId, 
        jump.properties, 
        jump.dataStatus, 
        jump.createdAt, 
        jump.updatedAt,
        jump.photo,
        jump.photos,
        jump.jumpLogs
      );
    } else {
      return null;
    }
  }
  
  export function jumpsToClassArray(jumps) {
    if (jumps) {
      const jumpsArray = jumps.map((item) => {
        return jumpToClass(item);
      });
      return jumpsArray;
    }
  }
  
  