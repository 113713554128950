/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React from 'react';
import {
  Row,
  Col,
  Alert,
  Card,
  Container,
  Button,
} from 'react-bootstrap';
import { Easel } from 'react-bootstrap-icons';
import CommunityUsersTable from './CommunityUsersTable';
/* eslint-enable no-unused-vars */

export default function CommunityPage() {
  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Alert>
            <Easel />
            &nbsp;&nbsp;Your Community
            <small> Grow your Community here at Full Gallop</small>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Button glyph="globe" href="/account" variant="primary">
            Return To Account
          </Button>{' '}
          <hr></hr>
          <Card variant="warning">
            <Card.Header>
              <Card.Title    >
                You have been Invited
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Button
                glyph="globe"
                href="/account/community/invites"
                variant="danger"
              >
                Click to see Who has Invited You to Join Their Community
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card variant="danger">
            <Card.Header>
              <Card.Title    >
                People In Your Community
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Button
                glyph="globe"
                href="/account/community/add"
                variant="info"
              >
                Click to Invite People to Join Your Community
              </Button>
              <br></br>
              <hr></hr>
              <CommunityUsersTable />
            </Card.Body>
            <Card.Footer>* Don't forget to check your invites</Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
