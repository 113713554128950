/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button } from 'react-bootstrap';
/* eslint-enable no-unused-vars */

class EntryPage extends Component {
  render() {
    return (
      <div>
        <Card variant="success">
          <Card.Header>
            <Card.Title    >My Entries</Card.Title>
          </Card.Header>
          <Card.Body>
            <Card variant="primary">
              <Card.Header>
                <Card.Title    >Entries</Card.Title>
              </Card.Header>
              <Card.Body>
              </Card.Body>
            </Card>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(EntryPage);
