import React from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import { Grid, List } from '@mui/material';
import _ from 'lodash';
import SummarizedPermission from './SummarizedPermission';

const SearchMain = styled(Grid)``;

const SearchMessages = styled.div`
  overflow-y: scroll;
  height: calc(65vh);
`;

function UserPermissionPanel() {
  const [selected, setSelected] = React.useState(-1);
  const searchedUser = useSelector(
    (state) => state.returnedUsers.userSearch.userInfo
  );

  const permissions = useSelector((state) => state.permissions);

  const handleClick = (event, id) => {
    setSelected(id);
  };

  const isSelected = (id) => selected === id;

  function listPermissions() {
    if (
      permissions &&
      searchedUser  &&
      searchedUser.id // searched user is a blank object, NOT null
    ) {
      return permissions.map((item, index) => {
        const isItemSelected = isSelected(item.id);
        var userPermission = null;
        if (searchedUser.perms) {
          userPermission = _.find(searchedUser.perms, {
            permissionId: item.id,
          });
        }
        return (
          <SummarizedPermission
            permission={item}
            aria-checked={isItemSelected}
            key={index}
            onClick={(event) => handleClick(event, item.id)}
            selected={isItemSelected}
            userPermission={userPermission}
            userId={searchedUser.id}
          />
        );
      });
    }
  }

  return (
    <SearchMain item xs={12} md={8} lg={9}>
      <SearchMessages>
        {searchedUser ? searchedUser.displayName : 'no user'}
        <List>{listPermissions()}</List>
      </SearchMessages>
    </SearchMain>
  );
}

export default UserPermissionPanel;
