/* AUTO GEN */
/* JumpLogItem.js */
export class JumpLogItem {
    constructor(
      id, 
      userId, 
      jumpId, 
      log, 
      type, 
      dataStatus,
      createdAt, 
      userName
    ) {
      this.id = id;
      this.userId = userId;
      this.jumpId = jumpId;
      this.log = log;
      this.type = type;
      this.dataStatus = dataStatus;
      this.createdAt = createdAt;
      this.userName = userName;
    }
  }
  
  export function jumpLogItemToClass(jumpLogItem) {
    if (jumpLogItem) {
      return new JumpLogItem(
        jumpLogItem.id, 
        jumpLogItem.userId, 
        jumpLogItem.jumpId, 
        jumpLogItem.log, 
        jumpLogItem.type, 
        jumpLogItem.dataStatus,
        jumpLogItem.createdAt,
        jumpLogItem.userName
      );
    } else {
      return null;
    }
  }
  
  export function jumpLogItemsToClassArray(jumpLogItems) {
    if (jumpLogItems) {
      const jumpLogItemsArray = jumpLogItems.map((item) => {
        return jumpLogItemToClass(item);
      });
      return jumpLogItemsArray;
    }
  }
  
  