/* AUTO GEN */
/* Entry.js */
export class Entry {
  constructor(
    id,
    userId,
    eventId,
    eventDivisionId,
    riderId,
    groupRiderId,
    eventHorseId,
    rideTimes,
    dataStatus,
    riderName,
    horseName,
    eventDivisionName,
    eventDivisionTests, 
    eventDivisionProperties
  ) {
    this.id = id;
    this.userId = userId;
    this.eventId = eventId;
    this.eventDivisionId = eventDivisionId;
    this.riderId = riderId;
    this.groupRiderId = groupRiderId;
    this.eventHorseId = eventHorseId;
    if (!rideTimes) {
      this.rideTimes = {};
    } else {
      this.rideTimes = rideTimes;
    }
    this.dataStatus = dataStatus;
    this.riderName = riderName;
    this.horseName = horseName;
    this.eventDivisionName = eventDivisionName;
    this.eventDivisionTests = eventDivisionTests;
    this.eventDivisionProperties = eventDivisionProperties;
  }

  getDressageTime() {
    if (
      this.rideTimes &&
      this.rideTimes.dressage &&
      this.rideTimes.dressage.time
    ) {
      return this.rideTimes.dressage.time;
    }
    return null;
  }

  setDressageTime(time, ringId, dressageTest) {
    console.log(dressageTest);
    this.rideTimes.dressage = {
        time,
        ringId,
        minutes: dressageTest.minutes,
        testName: dressageTest.name,
        testType: dressageTest.type,
      };
  }
}

export function entryToClass(entry) {
  if (entry) {
    return new Entry(
      entry.id,
      entry.userId,
      entry.eventId,
      entry.eventDivisionId,
      entry.riderId,
      entry.groupRiderId,
      entry.eventHorseId,
      entry.rideTimes,
      entry.dataStatus,
      entry.riderName,
      entry.horseName,
      entry.eventDivisionName,
      entry.eventDivisionTests,
      entry.eventDivisionProperties
    );
  } else {
    return null;
  }
}

export function entriesToClassArray(entries) {
  if (entries) {
    const entriesArray = entries.map((item) => {
      return entryToClass(item);
    });
    return entriesArray;
  }
}
