import React from 'react';
import styled from '@emotion/styled';
import { Card, Row, Col, Container } from 'react-bootstrap';
import HelmetTracker from '../../core/HelmetTracker';

/* MATERIAL UI */
import {
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import { spacing } from '@mui/system';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import UserInfoForm from '../forms/UserInfoForm';
import EventHorses from '../core/EventHorses';
import EventPeople from '../core/EventPeople';
import EventEntries from '../core/EventEntries';

import { useSelector } from 'react-redux';

const Spacer = styled.div(spacing);

const Centered = styled.div`
  text-align: center;
`;

export function UserPage() {
  const user = useSelector((state) => state.auth);  
  
  const handleVerifyClick = (event, rideTime) => {
    alert(" Verify Sent" );
  };

  function VerifyButton() {
    if (user && !user.verified)
      return (
        <Button mr={2} variant="contained" color="primary" size="small" onClick={handleVerifyClick} >
          Verify Email
        </Button>
      );
  }

  function Details() {
    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Profile Details
          </Typography>

          <Spacer mb={4} />

          <Centered>
            <Avatar
              alt={user && user.firstName}
              src="/static/img/avatars/avatar-1.jpg"
            />
            <Typography variant="body2" component="div" gutterBottom>
              <Box fontWeight="fontWeightMedium">
                {user && user.displayName}
              </Box>
              <Box fontWeight="fontWeightRegular">Lead Developer</Box>
            </Typography>
            <VerifyButton />
            <Button mr={2} variant="contained" color="primary" size="small">
              Message
            </Button>
          </Centered>
        </CardContent>
      </Card>
    );
  }

  return (
    <Container>
      <HelmetTracker title="My Home" />
      <Details />
      <Row>
        <Button>Start Here, {user && user.firstName}</Button>
        <Col xs={12} md={12}>
          <Card bg="light">
            <Card.Header bg="light">
              <Stack direction="row" spacing={5} alignItems="center">
                <Avatar>1</Avatar>
                <Card.Title>
                  <Box>Check Your Account Info</Box>
                </Card.Title>
              </Stack>
            </Card.Header>
            <Card.Body>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: 'gray',
                  }}
                >
                  <Typography>Your Basic Info</Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: 'gray',
                  }}
                >
                  <UserInfoForm />
                </AccordionDetails>
              </Accordion>
            </Card.Body>
          </Card>
          <br></br>
          <Card bg="light">
            <Card.Header bg="light">
              <Stack direction="row" spacing={5} alignItems="center">
                <Avatar>2</Avatar>
                <Card.Title>
                  <Box>Add (or Edit) Your Horses</Box>
                </Card.Title>
              </Stack>
            </Card.Header>
            <Card.Body>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: 'gray',
                  }}
                >
                  <Typography>Horses: </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: 'gray',
                  }}
                >
                  <EventHorses />
                </AccordionDetails>
              </Accordion>
            </Card.Body>
          </Card>
          <br></br>
          <Card bg="light">
            <Card.Header bg="light">
              <Stack direction="row" spacing={5} alignItems="center">
                <Avatar>3</Avatar>
                <Card.Title>
                  <Box>Add (or Edit) Your Riders/People</Box>
                </Card.Title>
              </Stack>
            </Card.Header>
            <Card.Body>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: 'gray',
                  }}
                >
                  <Typography>People: </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: 'gray',
                  }}
                >
                  <EventPeople />
                </AccordionDetails>
              </Accordion>
            </Card.Body>
          </Card>
          <br></br>
          <Card bg="light">
            <Card.Header bg="light">
              <Stack direction="row" spacing={5} alignItems="center">
                <Avatar>3</Avatar>
                <Card.Title>
                  <Box>Add (or Edit) Your Entries</Box>
                </Card.Title>
              </Stack>
            </Card.Header>
            <Card.Body>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: 'gray',
                  }}
                >
                  <Typography>Entries: </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: 'gray',
                  }}
                >
                  <EventEntries />
                </AccordionDetails>
              </Accordion>
            </Card.Body>
          </Card>
          <br></br>
        </Col>
      </Row>
    </Container>
  );
}

export default UserPage;
