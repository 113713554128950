import React, { useEffect, Fragment } from 'react';
import styled from '@emotion/styled';
import { PlusCircle, Edit } from 'react-feather';
import HelmetTracker from '../../core/HelmetTracker';

import { fetchProducts } from '../../../redux/actions/store/productsActions';
import { fetchProductCategories } from '../../../redux/actions/store/productCategoriesActions';
import { useDispatch, useSelector } from 'react-redux';
import ProductForm from '../forms/ProductForm';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import {
  Button,
  Card as MuiCard,
  CardHeader,
  IconButton,
  Paper as MuiPaper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { spacing } from '@mui/system';

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

export default function ProductsPage() {
  const [selected, setSelected] = React.useState(-1);
  const [creating, setCreating] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [addingTest, setAddingTest] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  // const [selected, setSelected] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchProductCategories());
  }, [dispatch]);

  const products = useSelector((state) => state.products);
  const product = useSelector((state) =>
    state.products.find((x) => x.id === selected)
  );

  // const productCategories = useSelector((state) => state.productCategories);
  const productCategories = useSelector((state) =>
    state.productCategories.filter((x) => x.parentCategoryId)
  );

  const handleClick = (event, id) => {
    if (!addingTest) {
      setSelected(id);
    }
  };

  const handleAddClick = (event) => {
    setSelected(-1);
    setEditing(false);
    setCreating(!creating);
  };

  const handleFormOkClick = (event) => {
    setCreating(false);
    setAddingTest(false);
    setEditing(false);
    setSelected(-1);
  };

  const handleFormCancelClick = (event) => {
    setCreating(false);
    setEditing(false);
  };

  const handleEditClick = (event, product) => {
    setSelected(product.id);
    setEditing(!editing);
    setCreating(false);
    // dispatch(createOculusEvent(oculusEvent('Favorites.Manage', 4, horse)));
    // if (horse.id) navigate('/horses/manage/' + horse.id);
  };

  const isSelected = (id) => selected === id;

  function mapBody(products) {
    if (products && products.length > 0) {
      const tableBody = products.map((product) => {
        const isItemSelected = isSelected(product.id);
        return (
          <Fragment key={product.id}>
            <TableRow
              key={product.id}
              aria-checked={isItemSelected}
              onClick={(event) => handleClick(event, product.id)}
              selected={isItemSelected}
            >
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open && isItemSelected ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row">
                {product.id}
              </TableCell>
              <TableCell>{product.name}</TableCell>
              <TableCell>{product.description}</TableCell>
              <TableCell>{product.category.categoryName}</TableCell>
              <TableCell>{product.sku()}</TableCell>
              <TableCell>{product.fullSku()}</TableCell>
              <TableCell>
                <Stack>
                  <Button
                    aria-label="edit"
                    onClick={(event) => handleEditClick(event, product)}
                    endIcon={<Edit />}
                    variant="outlined"
                    color="success"
                  >
                    Edit
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={8}
              >
                <Collapse
                  in={open && isItemSelected && product.stripe}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ margin: 1 }}>
                    <Typography variant="h6" gutterBottom component="div">
                      Details
                    </Typography>
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow>
                          <TableCell>Stripe Id</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Price</TableCell>
                          <TableCell>Active</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={product.id}>
                          <TableCell>{product.stripe.id}</TableCell>
                          <TableCell>{product.stripe.name}</TableCell>
                          <TableCell>{product.stripe.description}</TableCell>
                          <TableCell>Price soon</TableCell>
                          <TableCell>{product.stripe.active}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow> */}
          </Fragment>
        );
      });
      return tableBody;
    }
  }

  function showProductForm() {
    if (selected && selected > 0) {
      if (product && editing) {
        return (
          <ProductForm
            onOkClick={handleFormOkClick}
            onCancelClick={handleFormCancelClick}
            product={product}
            productCategories={productCategories}
          />
        );
      }
    } else if (creating) {
      return (
        <ProductForm
          onOkClick={handleFormOkClick}
          onCancelClick={handleFormCancelClick}
          productCategories={productCategories}
        />
      );
    }
  }

  const title = 'Products';

  return (
    <>
      <HelmetTracker title="Products" />
      <Card mb={6}>
        <CardHeader
          action={
            <IconButton
              aria-label="settings"
              size="large"
              onClick={(event) => handleAddClick(event)}
            >
              <PlusCircle />
            </IconButton>
          }
          title={title}
        />
        <Paper>
          <TableWrapper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Id</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>Full SKU</TableCell>
                  <TableCell>Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{mapBody(products)}</TableBody>
            </Table>
          </TableWrapper>
        </Paper>
        {showProductForm()}
      </Card>
    </>
  );
}
