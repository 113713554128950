/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Image } from 'react-bootstrap';
import ReactPlayer from 'react-player';
/* eslint-enable no-unused-vars */

export default function VideoCard({ video }) {
  function renderPlayer() {
    if (video && video.amazons3URL && video.amazons3URL.length > 0) {
      return (
        <div className='player-wrapper'>
        <ReactPlayer
          className='react-player'
            url={video.amazons3URL}
            width='100%'
            height='100%'
          />
        </div>
      );
    } else {
      return (
        <Image src="/images/videos/blank.png" alt="Avatar" rounded responsive />
      );
    }
  }

  return (
    <Col md={12} lg={12} xl={8} xxl={6}>
      <div className="card">
        {renderPlayer()}
      </div>
    </Col>
  );
}

VideoCard.propTypes = {
  video: PropTypes.object.isRequired,
};
