import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchCalendarEvent } from '../../../redux/actions/Events/eventActions';
import { Table } from 'react-bootstrap';
import EntryStatusTableRow from './EntryStatusTableRow';
import HelmetTracker from '../../core/HelmetTracker';

export default function CalendarEntryStatusTable() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(fetchCalendarEvent(id));
    }
  }, [dispatch, id]);

  const eventSelected  = useSelector(
    (state) =>
      state.calendar.find((m) => {
        return m.id === parseInt(id);
      }),
    shallowEqual
  );

  function tableEvents(eventSelected) {
    var i = 0;
    if (eventSelected) {
      return eventSelected.entryStatusData.map((entryStatus) => {
        i++;
        return <EntryStatusTableRow entryStatus={entryStatus} key={i} />;
      });
    }
  }

  const objId = id ? id : null;

  return (
    <>
      <HelmetTracker title="Entry Status" objId={objId} />
      <Table striped bordered >
        <thead>
          <tr>
            <th>Rider</th>
            <th>Horse</th>
            <th>Division</th>
            <th>Status</th>
            <th>Balance Due</th>
            <th>Deposit Balance</th>
            <th>Missind IDs</th>
            <th>Incomplete Forms</th>
            <th>Stabling</th>
            <th>Group</th>
          </tr>
        </thead>
        <tbody>{tableEvents(eventSelected)}</tbody>
      </Table>
    </>
  );
}
