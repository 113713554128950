import React from 'react';
import MinuteBox from './MinuteBox';
import { useDrop } from 'react-dnd';
import { ItemTypes } from './Constants';

import _ from 'lodash';

export default function MinuteDroppable({
  minuteId,
  time,
  ringId,
  onEntryTimeChanged,
  onEntryTimesArrayChanged,
  children,
}) {
  function moveScheduleBox(item, toTime, ringId) {
    if (item && item.type === ItemTypes.ENTRY_LIST) {
      setEntryDressageTimes(item, toTime, ringId);
    } else if (item && item.type === ItemTypes.DRESSAGE_ENTRY) {
      setEntryDressageTime(item, toTime, ringId);
      onEntryTimeChanged(item.entry);
    }
  }

  function getDressageTestFromDivision(eventDivisionTests) {
    if (eventDivisionTests) {
      return _.find(eventDivisionTests, function (test) {
        if (test.type === 0) {
          return true;
        }
      });
    } else {
      return null;
    }
  }

  function setEntryDressageTimes(item, toTime, ringId) {
    if (item && item.divisionEntries && item.divisionEntries.length > 0) {
      var arrayToUpdate = [];
      var test = getDressageTestFromDivision(item.entry.eventDivisionTests);
      let newDate = new Date(toTime);
      var offset = 0;
      item.divisionEntries.forEach((entry) => {
        let nextDate = new Date(newDate.getTime() + offset * 60000);
        entry.setDressageTime(nextDate, ringId, test);
        offset = offset + test.minutes;
        arrayToUpdate.push({ rideTimes: entry.rideTimes, entryId: entry.id });
      });
      if (arrayToUpdate.length > 0) {
        onEntryTimesArrayChanged(arrayToUpdate, item.eventId);
      }
    }
  }

  function setEntryDressageTime(item, toTime, ringId) {
    let time = new Date(toTime);
    if (item && item.entry && item.entry.rideTimes.dressage) {
      var test = getDressageTestFromDivision(item.entry.eventDivisionTests);
      item.entry.setDressageTime(time, ringId, test);
    } else if (item && item.entry && item.eventDivision) {
      var test2 = getDressageTestFromDivision(item.entry.eventDivisionTests);
      if (test2) {
        item.entry.eventDivision = item.eventDivision;
        item.entry.setDressageTime(time, ringId, test2);
      }
    }
  }

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [
        ItemTypes.SCHEDULE_BOX,
        ItemTypes.ENTRY_LIST,
        ItemTypes.DRESSAGE_ENTRY,
      ],
      drop: (item) => moveScheduleBox(item, time, ringId),
      collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    }),
    [time]
  );

  return (
    <div
      ref={drop}
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      <MinuteBox minuteBoxId={minuteId} time={time}>
        {children}
      </MinuteBox>
      {isOver && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
            opacity: 0.5,
            backgroundColor: 'yellow',
          }}
        />
      )}
    </div>
  );
}
