/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Container, Row, Col, ButtonToolbar, Button } from 'react-bootstrap';
import { PlusCircle } from 'react-bootstrap-icons';
import TackTable from './TackTable';
import { LinkContainer } from 'react-router-bootstrap';
/* eslint-enable no-unused-vars */

class TackPage extends Component {
  render() {
    return (
      <Container fluid>
        <Row className="show-grid">
          <Col xs={12} sm={12} md={12}>
            <Card variant="danger">
              <Card.Header>
                <Card.Title    >Tacks</Card.Title>
              </Card.Header>
              <Card.Body>
                <ButtonToolbar>
                  <LinkContainer to={'/tack/new'}>
                    <Button variant="primary">
                        Add Tack&nbsp;&nbsp;
                      <PlusCircle />
                    </Button>
                  </LinkContainer>
                </ButtonToolbar>
              </Card.Body>
            </Card>
            <Card variant="danger">
              <Card.Header>
                <Card.Title    >My Tack</Card.Title>
              </Card.Header>
              <Card.Body>
                <TackTable />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps({ tack }) {
  return { tack };
}

export default connect(mapStateToProps)(TackPage);
