import React from 'react';
import styled from '@emotion/styled';
import { spacing } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import {
  searchUsersByEmailAndName,
  getEditableUserData,
} from '../../../redux/actions/admin/searchActions';

import {
  Box,
  Grid,
  Card,
  TextField as MuiTextField,
  List,
  Divider as MuiDivider,
} from '@mui/material';
import SummarizedUser from './SummarizedUser';
import SearchStringDisplay from './SearchStringDisplay';
import UserPermissionPanel from './UserPermissionPanel';

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const SearchContainer = styled(Grid)`
  width: 100%;
  height: 65vh;
`;

const SearchSidebar = styled(Grid)`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
`;

function UserPermissionEditor() {
  const [selected, setSelected] = React.useState(-1);
  const dispatch = useDispatch();
  const searchedUsers = useSelector(
    (state) => state.returnedUsers.searchedUsers
  );
  const searchString = useSelector((state) => state.returnedUsers.searchString);

  const selectedUser = useSelector((state) =>
    state.returnedUsers.searchedUsers.find((x) => x.id === selected)
  );

  const handleClick = (event, id) => {
    setSelected(id);
    dispatch(getEditableUserData(id));
  };

  const isSelected = (id) => selected === id;

  function listSearchedUsers() {
    if (searchedUsers && searchedUsers.length > 0) {
      return searchedUsers.map((item, index) => {
        const isItemSelected = isSelected(item.id);
        return (
          <SummarizedUser
            searchedUser={item}
            aria-checked={isItemSelected}
            key={index}
            onClick={(event) => handleClick(event, item.id)}
            selected={isItemSelected}
          />
        );
      });
    }
  }

  function displaySearchString() {
    if (searchString && searchedUsers) {
      return (
        <SearchStringDisplay
          searchString={searchString}
          resultCount={searchedUsers.length}
        />
      );
    }
  }

  const handleKeyDown = (event) => {
    dispatch(
      searchUsersByEmailAndName(event.target.value.replace(/\s+/g, ' ').trim())
    );
  };

  return (
    <SearchContainer container component={Card}>
      <SearchSidebar item xs={12} md={4} lg={3}>
        <Grid item xs={12}>
          <Box p={2}>
            <TextField
              label="Search users"
              variant="outlined"
              fullWidth
              onChange={handleKeyDown}
            />
          </Box>
        </Grid>
        {displaySearchString()}
        <Divider />

        <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
          <List>{listSearchedUsers()}</List>{' '}
        </Box>
      </SearchSidebar>
      <UserPermissionPanel selectedUser={selectedUser} />
    </SearchContainer>
  );
}

export default UserPermissionEditor;
