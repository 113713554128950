import { SET_JUMPS, JUMP_FETCHED } from '../actions/types';

const INITIAL_STATE = [];

export default function reduce(state = INITIAL_STATE, action) {
  switch (action.type) {
    case JUMP_FETCHED:
      var itemFound = false;
      var newState = state.map((item) => {
        if (item.id === action.jump.id) {
          // Item UPDATED
          itemFound = true;
          return action.jump;
        } else return item;
      });
      if (!itemFound) {
        // Item CREATED
        newState.push(action.jump);
      }
      return [...newState];
    case SET_JUMPS:
      return action.jumps;
    default:
      return state;
  }
}
