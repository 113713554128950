import React, { useState } from 'react';
import MinuteDroppable from '../MinuteDroppable';
import { DressageBox } from './DressageBox';
import { useDispatch } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import {
  updateEntryTimes,
  updateEntryTimesArray,
} from '../../../redux/actions/Events/entryActions';

import styled from '@emotion/styled';
import { Chip as MuiChip } from '@mui/material';

const Chip = styled(MuiChip)`
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;

  span {
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

function getTypeChip(type) {
  if (type === 0) {
    return <Chip label="Dressage" color="error" />;
  }
  if (type === 1) {
    return <Chip label="Stadium" color="warning" />;
  }
  if (type === 2) {
    return <Chip label="Stadium" color="success" />;
  }
}

export default function ScheduleRing({ ring, entries, onSelectEntry }) {
  const [selectedEntry, setSelectedEntry] = useState(null);

  const dispatch = useDispatch();

  function renderMinuteBoxesFrom(startRenderTime, count) {
    var returnminutes = [];
    for (let i = 0; i < count; i++) {
      const newDateTime = new Date(startRenderTime);
      newDateTime.setMinutes(startRenderTime.getMinutes() + i);
      returnminutes.push(renderMinuteDroppable(newDateTime, i));
    }
    return returnminutes;
  }

  function renderMinuteDroppable(time, index) {
    // console.log(index);
    return (
      <div
        key={index}
        style={{
          width: '100%',
        }}
      >
        <MinuteDroppable
          key={index}
          minuteId={index}
          time={time}
          ringId={ring.id}
          onEntryTimeChanged={doEntryTimeChanged}
          onEntryTimesArrayChanged={doEntryTimesArrayChanged}
        />
      </div>
    );
  }

  function renderEntryBox(entry, index) {
    return (
      <div
        key={entry.id}
        style={{
          width: '100%',
        }}
      >
        <DressageBox
          id={entry.id}
          entry={entry}
          ringId={ring.id}
          durationMinutes={entry.rideTimes.dressage.minutes}
          onChangeTime={doEntryTimeChanged}
          onClick={handleBoxClick}
          selected={isSelectedEntry(entry)}
          key={entry.id}
        />
      </div>
    );
  }

  function renderMinuteBoxes() {
    var startTime = new Date(Date.parse(ring.startTime));
    var lastTimeRendered = new Date(startTime);
    var endTime = new Date(startTime);
    endTime.setTime(endTime.getTime() + ring.duration * 60000);

    var minuteBoxesNew = [];
    if (entries) {
      entries.forEach((entry) => {
        const entryTime = new Date(entry.rideTimes.dressage.time);
        var diffMins = entryTime - lastTimeRendered;
        var gap = diffMins / 60000;
        minuteBoxesNew.push(renderMinuteBoxesFrom(lastTimeRendered, gap));
        minuteBoxesNew.push(renderEntryBox(entry));
        lastTimeRendered.setTime(
          entryTime.getTime() + entry.rideTimes.dressage.minutes * 60 * 1000
        );
      });
    }

    var lastDiff = endTime - lastTimeRendered;
    var lastGap = lastDiff / 60000;
    if (lastGap < 1000) {  // not needed if math ok
      minuteBoxesNew.push(renderMinuteBoxesFrom(lastTimeRendered, lastGap));
    }

    return minuteBoxesNew;
  }

  function collisionTest(id, newTime, minutes) {
    var entryTime = new Date(newTime);
    var endEntryTime = new Date(entryTime.getTime() + minutes * 59999);
    var returnEntry = null;
    entries.forEach((entry) => {
      if (entry.id !== id) {
        var startTime = new Date(entry.rideTimes.dressage.time);
        var endTime = new Date(
          startTime.getTime() + entry.rideTimes.dressage.minutes * 59999
        );
        // Moving Up
        if (
          entryTime.getTime() >= startTime.getTime() &&
          entryTime.getTime() <= endTime.getTime()
        ) {
          returnEntry = entry;
        }

        // Moving Down
        if (
          endEntryTime.getTime() >= startTime.getTime() &&
          endEntryTime.getTime() <= endTime.getTime()
        ) {
          returnEntry = entry;
        }
      }
    });
    return returnEntry;
  }

  function doEntryTimeChanged(entry, shiftByOne, newTime) {
    // console.log('doEntryTimeChanged');
      if (shiftByOne) {
      var collidedEntry = collisionTest(
        entry.id,
        newTime,
        entry.rideTimes.dressage.minutes
      );
        var newDate = new Date(newTime);
      if (!collidedEntry) {
        entry.rideTimes.dressage.time = newDate;
        dispatch(
          updateEntryTimes({ entryId: entry.id, rideTimes: entry.rideTimes })
        );
      } else {
        // var newDate = new Date(newTime);
        entry.rideTimes.dressage.time = newDate;
        dispatch(
          updateEntryTimes({ entryId: entry.id, rideTimes: entry.rideTimes })
        );

        let newCollidedDate = new Date(newTime);
        newCollidedDate =
          newCollidedDate.getTime() + 60000 * entry.rideTimes.dressage.minutes;

        let textDate = new Date(newCollidedDate);
        console.log(textDate);
        // var newDate = new Date(collidedEntry.rideTimes.dressage.time);
        // console.log('colllided entry');
        // var updateArray = [];
        // var tmpDate = collidedEntry.rideTimes.dressage.time;
        // collidedEntry.rideTimes.dressage.time = textDate;
          doEntryTimeChanged(collidedEntry, shiftByOne, textDate);
        // entry.rideTimes.dressage.time = tmpDate;
        // updateArray.push({ rideTimes: entry.rideTimes, entryId: entry.id });
        // updateArray.push({
        //   rideTimes: collidedEntry.rideTimes,
        //   entryId: collidedEntry.id,
        // });

        // dispatch(
        //   updateEntryTimes({ entryId: entry.id, rideTimes: entry.rideTimes })
        // );

        // console.log('send array');
        // dispatch(
        //   updateEntryTimesArray({
        //     dataArray: updateArray,
        //     eventId: 115,
        //   })
        // );
      }
    } else {
      // console.log(entry);
      dispatch(
        updateEntryTimes({ entryId: entry.id, rideTimes: entry.rideTimes })
      );
    }
      
  }

  function doEntryTimesArrayChanged(dataArray, eventId) {
    dataArray.forEach((block) => {});
    dispatch(
      updateEntryTimesArray({
        dataArray,
        eventId,
      })
    );
  }

  function handleBoxClick(value) {
    onSelectEntry(value);
    setSelectedEntry(value);
  }

  function isSelectedEntry(entry) {
    return selectedEntry && entry.id === selectedEntry.id;
  }

  function returnCard() {
    return (
      <Card>
        <CardContent>
          <Typography variant="h3" component="div">
            {ring.name}
          </Typography>
          <Typography variant="h5" component="div">
            {getTypeChip(ring.type)}
          </Typography>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              border: '1px solid gray',
            }}
          >
            {renderMinuteBoxes()}
          </div>
        </CardContent>
      </Card>
    );
  }

  return returnCard();
}
