/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import ResetPassFormik from '../../formik/ResetPassFormik';
/* eslint-enable no-unused-vars */

class ResetPass extends Component {

  render() {
    const form = (
      <div>
        <Card variant="danger">
          <Card.Header>
            <Card.Title    >Password Reset Form</Card.Title>
          </Card.Header>
          <Card.Body>
            <ResetPassFormik token={this.props.match.params.token} />
          </Card.Body>
        </Card>
      </div>
    );
    return (
      <div>
        <Row className="show-grid">
          <Col xs={12} sm={6} md={6}>
            {form}
          </Col>
        </Row>
      </div>
    );
  }
}

export default ResetPass;
