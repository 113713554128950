/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React from 'react';
import _ from 'lodash';
import {
  Row,
  Col,
  Alert,
  Card,
  Container,
  Button,
  ButtonToolbar,
} from 'react-bootstrap';
import { Easel } from 'react-bootstrap-icons';
import CommunityInvitesToMeTable from './CommunityInvitesToMeTable';
// import CommunityUsersTable from './CommunityUsersTable';
/* eslint-enable no-unused-vars */

export default function CommunityInvitesPage() {
  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Alert>
            <Easel />
            &nbsp;&nbsp;Accept Community Invitations
            <small> Join Communities of others Here</small>
          </Alert>
          <ButtonToolbar>
            <Button glyph="globe" href="/account" variant="primary">
              Return To Account
            </Button>
            <Button glyph="globe" href="/account/community" variant="warning">
              Return To Community Page
            </Button>
          </ButtonToolbar>
          <hr></hr>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card variant="danger">
            <Card.Header>
              <Card.Title    >List of Invitations</Card.Title>
            </Card.Header>
            <Card.Body>
              <CommunityInvitesToMeTable />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
