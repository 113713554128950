import React from 'react';
import HorseVideoCard from './HorseVideoCard';
import { Table, Col } from 'react-bootstrap';

export default function HorseVideosGroup({ horse, onDeleteVideo, auth }) {
  function renderHorseVideos() {
    if (horse && horse.videos) {
      return horse.videos.map((horseVideo) => {
        if (horseVideo) {
          return (
            <tr key={horseVideo.id}>
              {auth ? (
                <HorseVideoCard
                  horseVideo={horseVideo}
                  onDelete={onDeleteVideo}
                />
              ) : (
                <HorseVideoCard
                  horseVideo={horseVideo}
                />
              )}
            </tr>
          );
        } else {
          return (
            <tr key={0}>
              <td>Null Video</td>
            </tr>
          );
        }
      });
    }
    return null;
  }

  return (
    <Col md={12} lg={12} xl={8} xxl={6}>
      <Table striped bordered hover>
        <tbody>{renderHorseVideos()}</tbody>
      </Table>
    </Col>
  );
}
