/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React from 'react';
import { Col, Image, Button } from 'react-bootstrap';
import moment from 'moment';
import ModalImage from 'react-modal-image';
/* eslint-enable no-unused-vars */

export default function TrainerPhotoCard({ trainerPhoto, onMakeMainPhoto }) {
  function dateToText(date) {
    return <p>{moment(date).format('M/D/YY - hh:mm a')}</p>;
  }

  function renderImgTag() {
    if (
      trainerPhoto &&
      trainerPhoto.amazons3URLHalf &&
      trainerPhoto.amazons3URLHalf.length > 0
    ) {
      return (
        <ModalImage
          small={trainerPhoto.amazons3URLHalf}
          medium={trainerPhoto.amazons3URLHalf}
          large={trainerPhoto.amazons3URL}
          alt="Hello World!"
          key={trainerPhoto.id}
        />
      );
    }
    if (
      trainerPhoto &&
      trainerPhoto.amazons3URL &&
      trainerPhoto.amazons3URL.length > 0
    ) {
      return (
        <ModalImage
          small={trainerPhoto.amazons3URL}
          medium={trainerPhoto.amazons3URL}
          alt="Hello World!"
          key={trainerPhoto.id}
        />
      );
    } else {
      return (
        <Image src="/images/photos/blank.png" alt="Avatar" rounded responsive />
      );
    }
  }

  function getDisplayName() {
    if (trainerPhoto && trainerPhoto.title) {
      return trainerPhoto.title;
    } else {
      if (trainerPhoto && trainerPhoto.description) {
        return trainerPhoto.description;
      } else {
        return '';
      } 
    }
  }

  function getUploadDate() {
    if (trainerPhoto && trainerPhoto.createdAt) {
      return dateToText(trainerPhoto.createdAt);
    } else {
      return 'Unknown Date';
    }
  }

  // handleDelete = (e) => {
  //   if (e) {
  //     e.preventDefault();
  //   }
  //   const trainerPhotoId = trainerPhoto.id;
  //   onDelete({ trainerPhotoId });
  // };

  //   function getDeleteButton() {
  // if (onDelete) {
  //   return (
  //     <Button onClick={this.handleDelete} variant="danger">
  //       Delete
  //     </Button>
  //   );
  // }
  //   }

  function handleMakeMainPhoto(e) {
    if (e) {
      e.preventDefault();
    }
    const photoURL = trainerPhoto.amazons3URLThumb;
    onMakeMainPhoto({ photoURL });
  }

  function getMakeMainPhotoButton() {
    if (onMakeMainPhoto) {
      return (
        <Button onClick={handleMakeMainPhoto} variant="info">
          Make Main Photo
        </Button>
      );
    }
  }

  return (
    <td>
      <div className="card">
        {renderImgTag()}
        <div className="container">
          <h4>{getDisplayName()}</h4>
          <h5>{getUploadDate()}</h5>
          {/* <p>{getDeleteButton()}</p> */}
          <p>{getMakeMainPhotoButton()}</p>
        </div>
      </div>
    </td>
  );
}
