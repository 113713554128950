import axios from 'axios';
import { JUMP_FETCHED, SET_JUMPS } from './types';
import { jumpToClass, jumpsToClassArray } from '../classes/Jump';

export function setJumps(jumps) {
  return {
    type: SET_JUMPS,
    jumps: jumpsToClassArray(jumps),
  };
}

export function jumpFetched(jump) {
  return {
    type: JUMP_FETCHED,
    jump: jumpToClass(jump),
  };
}

export const addNewJump = (values) => async (dispatch) => {
  const res = await axios.post('/api/jumps/create', values);
  dispatch(jumpFetched(res.data));
};

export const createJump = (values) => async (dispatch) => {
  const res = await axios.post('/api/jumps/create', values);
  dispatch(jumpFetched(res.data));
};

export const updateProperties = (values) => async (dispatch) => {
  const res = await axios.post('/api/jumps/update/properties', values);
  dispatch(jumpFetched(res.data));
};

export const fetchJumps = () => async (dispatch) => {
  const res = await axios.get('/api/jumps/read');
  dispatch(setJumps(res.data));
};

export const fetchJump = (id) => async (dispatch) => {
  const res = await axios.get('/api/jumps/read/' + id);
  dispatch(jumpFetched(res.data));
};

export const updateJump = (values) => async (dispatch) => {
  const res = await axios.post('/api/jumps/update', values);
  dispatch(jumpFetched(res.data));
};

export const addPhotoToJump = (values) => async (dispatch) => {
  const res = await axios.post('/api/jumps/photos/new/multi', values);
  dispatch(fetchJump(res.data.id));
};

export const deleteJumpPhoto = (values) => async (dispatch) => {
  const res = await axios.post('/api/jumps/photos/delete', values);
  dispatch(jumpFetched(res.data));
};

export const setMainPhotoForJump = (values) => async (dispatch) => {
  const res = await axios.post('/api/jumps/photos/setmain', values);
  dispatch(fetchJump(res.data.id));
};
