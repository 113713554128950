import {
  ENTRIES_FETCHED,
  ENTRY_CREATED,
  ENTRY_UPDATED,
} from '../../actions/types';
const _ = require('lodash');

const INITIAL_STATE = { entries: [], dressage: [], stadium: [] };

export default function reduce(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ENTRIES_FETCHED:
      return {
        all: [...action.entries],
        dressage: _.orderBy(
          action.entries,
          ['rideTimes.dressage.time'],
          ['asc']
        ),
        stadium: _.orderBy(action.entries, ['rideTimes.stadium.time'], ['asc']),
      };
    case ENTRY_CREATED:
      return [...action.entrys, action.entry];
    case ENTRY_UPDATED:
      var newState = state.map((item) => {
        if (item.id === action.entry.id) {
          return action.entry;
        } else return item;
      });
      return [...newState];
    default:
      return state;
  }
}
