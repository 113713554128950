/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  ButtonToolbar,
  Badge,
} from 'react-bootstrap';
import { Person } from 'react-bootstrap-icons';
import { LinkContainer } from 'react-router-bootstrap';
/* eslint-enable no-unused-vars */

function Dashboard() {
  const auth = useSelector((state) => state.auth);
  const horses = useSelector((state) => state.horses);
  const barns = useSelector((state) => state.barns);

  function getDisplayName() {
    switch (auth) {
      case null:
        return 'Awaiting user';
      case false:
        return 'No user';
      default:
        return auth.displayName;
    }
  }

  function getID() {
    switch (auth) {
      case null:
        return '...';
      case false:
        return '...';
      default:
        return '(User ID: ' + auth.id + ')';
    }
  }

  function verifyUserAccount() {}

  function getUserNotVerifiedControls() {
    switch (auth) {
      case null:
        return;
      case false:
        return;
      default:
        if (!auth.verified) {
          return (
            <Button variant="danger" onClick={verifyUserAccount()}>
              Verify User Account
            </Button>
          );
        } else {
          return <Button variant="danger">Verified</Button>;
        }
    }
  }

  return (
    <Container>
      <Row className="show-grid">
        <Col xs={12} sm={3} md={4}>
          <Card variant="danger">
            <Card.Header>
              <Card.Title>User Logged In</Card.Title>
            </Card.Header>
            <Card.Body>
              {getDisplayName()}
              {'   '}
              {getID()}
              {getUserNotVerifiedControls()}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={9} md={8}>
          <Card variant="danger">
            <Card.Header>
              <Card.Title>
                <Person /> User Tools
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <ButtonToolbar>
                <LinkContainer to={'/account/edit'}>
                  <Button variant="info">Edit Info</Button>
                </LinkContainer>
                <LinkContainer to={'/initiateresetpass'}>
                  <Button variant="danger">Reset Password</Button>
                </LinkContainer>
              </ButtonToolbar>
              <hr></hr>
              <ButtonToolbar>
                <LinkContainer to={'/horses'}>
                  <Button variant="info">
                    Your Horses&nbsp;&nbsp;
                    <Badge variant="light">
                      {Object.keys(horses).length}
                    </Badge>
                  </Button>
                </LinkContainer>
                <LinkContainer to={'/barns'}>
                  <Button variant="primary">
                    Your Barns&nbsp;&nbsp;
                    <Badge variant="light">
                      {Object.keys(barns).length}
                    </Badge>
                  </Button>
                </LinkContainer>
              </ButtonToolbar>
              <hr></hr>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
