import React from 'react';

import { ListItem, ListItemText } from '@mui/material';

function SearchStringDisplay({ searchString, resultCount }) {
  function displaySearchString() {
    return 'Searched: "' + searchString + '"';
  }
  function displayResultCount() {
    return 'Result Count: ' + resultCount;
  }

  return (
    <ListItem>
      <ListItemText
        primary={displaySearchString()}
        secondary={displayResultCount()}
      />
    </ListItem>
  );
}

export default SearchStringDisplay;
