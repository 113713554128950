import React from 'react';
import HorsePhotosGroup from './HorsePhotosGroup';

export default function HorsePedigree({ horse }) {
  return (
    <div>
      {horse && horse.photos ? (
        <HorsePhotosGroup horse={horse} />
      ) : (
        'No photos listed.'
      )}
    </div>
  );
}
