import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { PlusCircle, PlusSquare } from 'react-feather';

import { useSelector } from 'react-redux';
import PermissionsForm from '../forms/PermissionsForm';
import {
  Button,
  Card as MuiCard,
  CardHeader,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { spacing } from '@mui/system';
import PermissionPropertiesForm from '../forms/PermissionPropertiesForm';
import { PropertyItem } from '../core/PropertyItem';

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 700;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : 'primary']};
`;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

export default function PermissionsTable({ permissions }) {
  const [selected, setSelected] = React.useState(-1);
  const [creating, setCreating] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [addingProperty, setAddingProperty] = React.useState(false);

  function propertiesToUI(properties) {
    var i = 0;
    if (properties) {
      return (
        <>
          {properties.map(function (property) {
            return <PropertyItem property={property} key={i++} />;
          })}
        </>
      );
    }
  }

  //   const permissions = useSelector((state) => state.permissions);
  const selectedPermission = useSelector((state) =>
    state.permissions.find((x) => x.id === selected)
  );

  const handleAddPropertyClick = (event, id) => {
    setSelected(id);
    setAddingProperty(!addingProperty);

    // Stops it from selecting Row and showing full edit form.
    event.stopPropagation();
  };

  function getIdChip(permission) {
    if (permission.isSalePermission) {
      return <Chip label={permission.id} color="success" />;
    } else {
      return <Chip label={permission.id} color="error" />;
    }
  }

  function getPermissionTypeChip(permission) {
    if (permission && permission.type === 0) {
      return <Chip label="Client" color="info" />;
    } else if (permission.type === 1) {
      return <Chip label="Server" color="warning" />;
    } else {
      return '';
    }
  }

  const handleClick = (event, id) => {
    if (!addingProperty) {
      setSelected(id);
    }
  };

  const handleAddClick = (event) => {
    setSelected(-1);
    setEditing(false);
    setCreating(!creating);
  };

  const handleFormOkClick = (event) => {
    setCreating(false);
    setEditing(false);
    setAddingProperty(false);
    setSelected(-1);
  };

  const handleFormCancelClick = (event) => {
    setCreating(false);
    setEditing(false);
  };

  const isSelected = (id) => selected === id;

  function showPermissionForm() {
    if (selected && selected > 0) {
      if (selectedPermission && editing) {
        return (
          <PermissionsForm
            onOkClick={handleFormOkClick}
            onCancelClick={handleFormCancelClick}
            permission={selectedPermission}
          />
        );
      }
    } else if (creating) {
      return (
        <PermissionsForm
          onOkClick={handleFormOkClick}
          onCancelClick={handleFormCancelClick}
        />
      );
    }
  }

  function addPropertyForm(isSelected) {
    if (isSelected) {
      if (addingProperty) {
        return (
          <PermissionPropertiesForm
            onOkClick={handleFormOkClick}
            permission={selectedPermission}
          />
        );
      }
    }
  }

  function mapBody(permissions) {
    if (permissions && permissions.length > 0) {
      const tableBody = permissions.map((permission) => {
        const isItemSelected = isSelected(permission.id);
        const properties = permission.properties
          ? propertiesToUI(permission.properties)
          : '';
        return (
          <Fragment key={permission.id}>
            <TableRow
              key={permission.id}
              aria-checked={isItemSelected}
              onClick={(event) => handleClick(event, permission.id)}
              selected={isItemSelected}
            >
              <TableCell component="th" scope="row">
                {getIdChip(permission)}
              </TableCell>
              <TableCell>{permission.permission}</TableCell>
              <TableCell>{getPermissionTypeChip(permission)}</TableCell>
              <TableCell>
                {' '}
                <Stack>
                  <Button
                    aria-label="addTest"
                    onClick={(event) =>
                      handleAddPropertyClick(event, permission.id)
                    }
                    endIcon={<PlusSquare />}
                    variant="outlined"
                    color="success"
                  >
                    {!addingProperty ? 'Add Property' : 'Cancel'}
                  </Button>
                  {addPropertyForm(isItemSelected)}
                  {properties}
                </Stack>
              </TableCell>
              <TableCell>{permission.notes}</TableCell>
            </TableRow>
          </Fragment>
        );
      });
      return tableBody;
    }
  }

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            size="large"
            onClick={(event) => handleAddClick(event)}
          >
            <PlusCircle />
          </IconButton>
        }
        title="Permissions"
      />
      {showPermissionForm()}
      <Paper>
        <TableWrapper>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Properties</TableCell>
                <TableCell>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{mapBody(permissions)}</TableBody>
          </Table>
        </TableWrapper>
      </Paper>
    </Card>
  );
}
