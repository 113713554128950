/* AUTO GEN */
/* Ring.js */
export class Ring {
    constructor(
      id, 
      eventId, 
      name, 
      type, 
      startTime, 
      duration
    ) {
      this.id = id;
      this.eventId = eventId;
      this.name = name;
      this.type = type;
      this.startTime = startTime;
      this.duration = duration;
    }
  }
  
  export function ringToClass(ring) {
    if (ring) {
      return new Ring(
        ring.id, 
        ring.eventId, 
        ring.name, 
        ring.type, 
        ring.startTime, 
        ring.duration
      );
    } else {
      return null;
    }
  }
  
  export function ringsToClassArray(rings) {
    if (rings) {
      const ringsArray = rings.map((item) => {
        return ringToClass(item);
      });
      return ringsArray;
    }
  }
  
  