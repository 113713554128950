export const ItemTypes = {
  SCHEDULE_BOX: "schedule_box",
  ENTRY_LIST: "entry_list",
  DRESSAGE_LIST: "dressage_list",
  DRESSAGE_ENTRY: "dressage_entry",
  STADIUM_LIST: "stadium_list",
  STADIUM_ENTRY: "stadium_entry",
  XC_LIST: "xc_list",
  XC_ENTRY: "xc_entry",
};
