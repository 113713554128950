/* (( *** AUTO GEN *** )) */
/* ringActions.js */
import axios from 'axios';
import { SET_RINGS, RING_FETCHED } from '../types';
import { ringToClass, ringsToClassArray } from '../../classes/scheduler/Ring';

export function ringFetched(ring) {
  return {
    type: RING_FETCHED,
    ring: ringToClass(ring),
  };
}

export function setRings(rings) {
  return {
    type: SET_RINGS,
    rings: ringsToClassArray(rings),
  };
}

export const fetchRing = (id) => async (dispatch) => {
  const res = await axios.get('/api/rings/read/' + id);
  dispatch(ringFetched(res.data));
};
export const fetchRingsById = (id) => async (dispatch) => {
  const res = await axios.get('/api/rings/read/' + id);
  dispatch(setRings(res.data));
};

export const fetchRingsByEventId = (eventId) => async (dispatch) => {
  const res = await axios.get('/api/rings/read/eventId/' + eventId);
  dispatch(setRings(res.data));
};

export const createRing = (values) => async (dispatch) => {
  const res = await axios.post('/api/rings/create', values);
  dispatch(ringFetched(res.data));
};

export const updateRing = (values) => async (dispatch) => {
  const res = await axios.post('/api/rings/update', values);
  dispatch(ringFetched(res.data));
};

export const deleteRing = (values) => async (dispatch) => {
  const res = await axios.post('/api/rings/update', values);
  dispatch(setRings(res.data));
};

export const fetchRingsForEvent = (eventId) => async (dispatch) => {
  const res = await axios.get('/api/rings/read/event/' + eventId);
  dispatch(setRings(res.data));
};
