import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  Alert,
  Card,
  ListGroup,
  ListGroupItem,
  Container,
  Button,
  Image,
} from 'react-bootstrap';
import { fetchSaleHorse } from '../../redux/actions/saleHorseActions';
import HorsePedigree from '../customers/horses/core/HorsePedigree';
import SaleVideoCard from '../core/SaleVideoCard';
import HelmetTracker from '../core/HelmetTracker';

import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lightgallery-bundle.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const cardTheme = 'dark';

export function SaleHorseListingPage({ auth }) {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(fetchSaleHorse(id));
    }
  }, [dispatch, id]);

  const lightGallery = useRef(null);

  const [container, setContainer] = useState(null);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const [videosExpanded, setVideosExpanded] = useState(true);

  //const salehorse = useSelector((state) => state.saleshorses[id]);
  const salehorse = useSelector(
    (state) =>
      state.saleshorses.find((m) => {
        return m.id === parseInt(id);
      }),
    shallowEqual
  );

  function getSaleHorseName() {
    if (salehorse) {
      return salehorse.name;
    }
    return 'Sale Horse';
  }

  function getBriefDescription() {
    if (salehorse) {
      // console.log('salehorse');
      // console.log(salehorse);
      return <h5>{salehorse.description}</h5>;
    }
    return '';
  }

  function formatAsDollars(dollarValue) {
    if (dollarValue) {
      let formatting_options = {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      };
      let dollarString = new Intl.NumberFormat('en-US', formatting_options);
      return dollarString.format(dollarValue);
    }
  }

  function getGenderText(gender) {
    if (gender) {
      if (gender === 0) return 'No Gender Selected';
      if (gender === 1) return 'Gelding';
      if (gender === 2) return 'Mare';
      if (gender === 3) return 'Stallion';
    }
    return '';
  }

  function getGeneralInformation() {
    if (salehorse) {
      return (
        <ListGroup>
          <ListGroupItem>
            Price: {formatAsDollars(salehorse.price)}
          </ListGroupItem>
          <ListGroupItem>Breed: {salehorse.breed}</ListGroupItem>
          <ListGroupItem>
            Gender: {getGenderText(salehorse.gender)}
          </ListGroupItem>
          <ListGroupItem>Color: {salehorse.color}</ListGroupItem>
          <ListGroupItem>Height: {salehorse.height}</ListGroupItem>
          <ListGroupItem>Birth Year: {salehorse.birthYear}</ListGroupItem>
          <ListGroupItem>
            Level Competing: {salehorse.levelCompeting}
          </ListGroupItem>
        </ListGroup>
      );
    }
    return <ListGroup></ListGroup>;
  }

  function getPhotoItems() {
    if (salehorse && salehorse.photos && salehorse.photos.length > 0) {
      let photosArray = salehorse.photos;
      // let photosArray = salehorse.photos.sort((a, b) =>
      //   a.createdAt < b.createdAt ? 1 : -1
      // );
      return photosArray.map((photo) => {
        return (
          <div key={photo.id}>
            <img src={photo.amazons3URL} alt="" />
          </div>
        );
      });
    } else {
      return null;
    }
  }

  function getGallery() {
    return (
      <Carousel swipeable={true} dynamicHeight={true} emulateTouch={true}>
        {getPhotoItems()}
      </Carousel>
    );
  }

  function getGalleryPhotoArray() {
    if (salehorse && salehorse.photos && salehorse.photos.length > 0) {
      let photosArray = salehorse.photos;
      // let photosArray = salehorse.photos.sort((a, b) =>
      //   a.createdAt < b.createdAt ? 1 : -1
      // );
      return photosArray.map((photo) => {
        return {
          id: photo.id,
          src: photo.amazons3URL,
          thumb: photo.amazons3URLThumb,
        };
      });
    } else {
      return null;
    }
  }

  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
      lightGallery.current.openGallery();
    }
  }, []);

  const setContainerRef = useCallback((node) => {
    if (node !== null) {
      setContainer(node);
    }
  }, []);

  const getLgComponent = () => {
    var photoArray = getGalleryPhotoArray();
    // console.log('getLgComponent');
    // console.log(photoArray);
    if (container !== null && photoArray !== null) {
      return (
        <LightGallery
          plugins={[lgZoom, lgThumbnail]}
          container={container}
          elementClassNames="custom-classname"
          dynamic
          dynamicEl={photoArray}
          closable={false}
          showMaximizeIcon
          onInit={onInit}
        ></LightGallery>
      );
    }
    return '';
  };

  function renderVideos() {
    if (salehorse && salehorse.videos && salehorse.videos.length > 0) {
      let videosArray = salehorse.videos.sort((a, b) =>
        a.createdAt < b.createdAt ? 1 : -1
      );
      return videosArray.map((video) => {
        return (
          <ListGroupItem key={video.id}>
            <SaleVideoCard video={video} key={video.id} />
          </ListGroupItem>
        );
      });
    } else {
      return (
        <div>
          <Image
            src="/images/FGFarmLogo150h.PNG"
            alt="Avatar"
            rounded
            responsive={+true}
          />
        </div>
      );
    }
  }

  function videosBody() {
    if (videosExpanded) {
      return <ListGroup>{renderVideos()}</ListGroup>;
    }
  }

  function expandVideosButton() {
    if (videosExpanded) {
      return (
        <Button variant="success" onClick={() => setVideosExpanded(false)}>
          Hide Videos
        </Button>
      );
    } else {
      return (
        <Button variant="success" onClick={() => setVideosExpanded(true)}>
          Show Videos
        </Button>
      );
    }
  }
  const videosPanel = (
    <Card bg="success">
      <Card.Header>
        <h2>
          Videos{'  '} {expandVideosButton()}
        </h2>
      </Card.Header>
      <Card.Body>{videosBody()}</Card.Body>
      <Card.Footer />
    </Card>
  );

  const objId = salehorse ? salehorse.id : null;
  const userId = auth ? auth.id : null;

  return (
    <Container fluid>
      {salehorse ? (
        <HelmetTracker
          title={getSaleHorseName()}
          objId={objId}
          userId={userId}
        />
      ) : (
        ''
      )}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Alert>
            <h3>{getSaleHorseName()}</h3>
            <div className="text-right">
              <Button onClick={goBack} variant="danger" className="text-right">
                Back
              </Button>
            </div>
          </Alert>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card bg="info">
            <Card.Header>
              <Card.Title>
                <h3>Description</h3>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>{getBriefDescription()}</ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card bg="info">
            <Card.Header>
              <Card.Title>
                <h3>General Information</h3>
              </Card.Title>
            </Card.Header>
            <Card.Body>{getGeneralInformation()}</Card.Body>
          </Card>
        </Col>
      </Row>
      {salehorse &&
      salehorse.pedigree &&
      !salehorse.pedigreeEmptyOrPartial() ? (
        <Row className="pb-3">
          <Col xs={12} sm={12} md={12} lg={12}>
            <Card bg={cardTheme}>
              <Card.Header className="nav-item-text">
                <Card.Title>
                  <h3>Pedigree</h3>
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <HorsePedigree horse={salehorse} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        ''
      )}
      <Row className="pb-3">
        <Col xs={12} sm={12} md={12} lg={12} className="d-none d-lg-block">
          <Card bg="danger">
            <Card.Header>
              <Card.Title>
                <h3>Photos</h3>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <div
                style={{ width: '100%', height: '1000px' }}
                ref={setContainerRef}
                className="d-flex justify-content-center"
              ></div>
              {getLgComponent()}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} className="d-lg-none">
          <Card bg="danger">
            <Card.Header>
              <Card.Title>
                <h3>Photos</h3>
              </Card.Title>
            </Card.Header>
            {getGallery()}
          </Card>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col xs={12} sm={12} md={12} lg={12}>
          {videosPanel}
        </Col>
      </Row>
      <Row className="pb-3">
        <Col xs={12} sm={6} md={6} lg={6}>
          <Card variant="info">
            <Card.Header>
              <Card.Title>
                <h3>Cell Phone</h3>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  {salehorse && salehorse.id === 268
                    ? 'Brian Kilgo Kelly - (646) 416-1001'
                    : 'Lara Anderson - (803) 215-6590'}
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={6} lg={6}>
          <Card variant="info">
            <Card.Header>
              <Card.Title>
                <h3>Email</h3>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  {salehorse && salehorse.id === 268
                    ? 'Brian Kilgo Kelly - bkkeventing@gmail.com'
                    : 'Lara Anderson - fullgallopfarm@gmail.com'}
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default SaleHorseListingPage;
