/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React from 'react';
import { Col, Image, Button } from 'react-bootstrap';
import moment from 'moment';
import ReactPlayer from 'react-player';
/* eslint-enable no-unused-vars */

export default function TrainerVideoCard({ trainerVideo, onDelete, userLoggedInIsTrainer }) {
    function dateToText(date) {
      return <p>{moment(date).format('M/D/YY - hh:mm a')}</p>;
    }

  function renderPlayer() {
    if (trainerVideo && trainerVideo.amazons3URL && trainerVideo.amazons3URL.length > 0) {
      console.log('trainerVideo.amazons3URL :>> ', trainerVideo.amazons3URL);
      return (
          <ReactPlayer url={trainerVideo.amazons3URL} />
      );
    } else {
      return (
        <Image src="/images/videos/blank.png" alt="Avatar" rounded responsive />
      );
    }
  }

    function getUploadDate() {
      if (trainerVideo && trainerVideo.createdAt) {
        return dateToText(trainerVideo.createdAt);
      } else {
        return 'Unknown Date';
      }
    }

  function handleDelete(e) {
    if (e) {
      e.preventDefault();
    }
    const videoId = trainerVideo.trainerVideoId;
    onDelete({ videoId });
  }  
  
  function getDisplayName() {
    // if (trainerVideo && trainerVideo.title) {
    //   return trainerVideo.title;
    // } else {
    //   if (trainerVideo && trainerVideo.description) {
    //     return trainerVideo.description;
    //   } else {
        return '';
    //   }
    // }
  }

  function getDeleteButton() {
    console.log('userLoggedInIsTrainer :>> ', userLoggedInIsTrainer);
    if (onDelete && userLoggedInIsTrainer) {
      return (
        <Button onClick={handleDelete} variant="danger">
          Delete
        </Button>
      );
    }
  }

  return (
    <td>
      <div className="card">
        {renderPlayer()}
        <div className="container">
          <h4>{getDisplayName()}</h4>
          <h5>{getUploadDate()}</h5>
          <p>{getDeleteButton()}</p>
        </div>
      </div>
    </td>
  );
}
