/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import InitiateResetPassFormik from '../../formik/InitiateResetPassFormik';
/* eslint-enable no-unused-vars */

class InitiateResetPass extends Component {
  render() {
    const form = (
      <Card variant="danger">
        <Card.Header>
          <Card.Title    >Password Reset Form</Card.Title>
        </Card.Header>
        <Card.Body>
          <InitiateResetPassFormik />
        </Card.Body>
      </Card>
    );
    return (
      <Container>
        <Row className="show-grid">
          <Col xs={12} sm={6} md={6}>
            {form}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default InitiateResetPass;
