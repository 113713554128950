/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { redirect } from 'react-router';
import JumpAddForm from './JumpAddForm';
import { Container } from 'react-bootstrap';
/* eslint-enable no-unused-vars */

class JumpAddFormPage extends Component {
  state = {
    redirect: false,
  };

  addJump = (data) => {
    return this.props.addNewJump(data).then(() => {
      this.setState({ redirect: true });
    });
  };

  render() {
    return (
      <Container fluid>
        {this.state.redirect ? redirect("/jumps") : <JumpAddForm />}
      </Container>
    );
  }
}

export default JumpAddFormPage;
