/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';
/* eslint-enable no-unused-vars */

export default function EventDocumentCard({ eventDocument }) {
  function dateToText(date) {
    return <p>{moment(date).format('M/D/YY - hh:mm a')}</p>;
  }

  function getDisplayName() {
    if (eventDocument && eventDocument.title) {
      return eventDocument.title;
    } else {
      if (eventDocument && eventDocument.description) {
        return eventDocument.description;
      } else {
        return '';
      }
    }
  }

  function getUploadDate() {
    if (eventDocument && eventDocument.createdAt) {
      return dateToText(eventDocument.createdAt);
    } else {
      return 'Unknown Date';
    }
  }

  return (
    <td>
      <div className="card">
        <div className="container">
          <h4>{getDisplayName()}</h4>
          <h5>{getUploadDate()}</h5>
          {/* <p>{getDeleteButton()}</p> */}
          <Button href={eventDocument.amazons3URL} variant="warning">
            View Document
          </Button>
        </div>
      </div>
    </td>
  );
}
