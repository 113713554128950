import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { fetchCalendarEvent } from '../../../redux/actions/Events/eventActions';
import { useParams } from 'react-router-dom';
// import { useNavigate, useParams } from 'react-router-dom';
// import HelmetTracker from '../../../core/HelmetTracker';

import {
//   Box,
//   Button,
//   Card as MuiCard,
//   CardHeader,
//   IconButton,
//   Chip as MuiChip,
//   Paper as MuiPaper,
  Stack,
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
} from '@mui/material';

import { Alert } from 'react-bootstrap';
import { Clock } from 'react-bootstrap-icons';

export default function EventDetails() {
  const { id } = useParams();

  const dispatch = useDispatch();
//   const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      document.title = 'Edit Jump';
      dispatch(fetchCalendarEvent(id));
    } else {
      document.title = 'Add Jump';
    }
  }, [dispatch, id]);

  const calendarEvent = useSelector(
    (state) =>
      state.calendar.find((m) => {
        return m.id === parseInt(id);
      }),
    shallowEqual
  );

  return (
    <div>
      <Alert>
        <h2>
          <Clock />
          &nbsp;&nbsp; Event Details
        </h2>
      </Alert>
      <Stack>
        <div>id: {calendarEvent ? calendarEvent.id : ''}</div>
        <div>{calendarEvent ? calendarEvent.title : ''}</div>
      </Stack>
    </div>
  );
}
