import React, { Fragment, useEffect } from 'react';
import styled from '@emotion/styled';
import { PlusCircle, Edit } from 'react-feather';
import HelmetTracker from '../../core/HelmetTracker';
import { dateToText } from '../../core/DateTools';

import { fetchTasksByUserId } from '../../../redux/actions/taskActions';
// import { fetchTaskCategories } from '../../../redux/actions/store/taskCategoriesActions';
import { useDispatch, useSelector } from 'react-redux';
import TaskForm from './forms/TaskForm';

import {
  Box,
  Button,
  Card as MuiCard,
  CardHeader,
  Collapse,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { spacing } from '@mui/system';
import { tasksToClassArray } from '../../../redux/classes/tasks/Task';

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 700;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : 'primary']};
`;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

export default function TasksPage({ auth }) {
  const [selected, setSelected] = React.useState(-1);
  const [creating, setCreating] = React.useState(false);
  const [editing, setEditing] = React.useState(false);

  const [addingTest, setAddingTest] = React.useState(false);
  // const [selected, setSelected] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth) {
      dispatch(fetchTasksByUserId(auth.id));
    }
  }, [dispatch, auth]);

  const tasks = useSelector((state) => state.tasks);
  const selectedTask = useSelector((state) =>
    state.tasks.find((x) => x.id === selected)
  );

  const handleClick = (event, id) => {
    if (!addingTest) {
      if (selected < 0) {
        setSelected(id);
      } else {
        setSelected(-1);
      }
    }
  };

  const handleEditClick = (event, id) => {
    setEditing(!editing);
    setSelected(id);
    event.stopPropagation();
  };

  const handleAddClick = (event) => {
    setSelected(-1);
    setCreating(!creating);
  };

  const handleFormOkClick = (event) => {
    setCreating(false);
    setAddingTest(false);
    setSelected(-1);
  };

  function getPriorityBadge(task) {
    if (tasksToClassArray) {
      return (
        <Chip label={task.getPriority()} color={task.getPriorityColor()} />
      );
    }
    return '';
  }

  function getDateBadge(task) {
    if (task) {
      return (
        <Chip label={dateToText(task.due)} color={task.getPriorityColor()} />
      );
    }
    return '';
  }

  function getEditButton(task) {
    return (
      <Button
        aria-label="edit"
        onClick={(event) => handleEditClick(event, task.id)}
        endIcon={<Edit />}
        variant="outlined"
        color="success"
      />
    );
  }

  function showTaskForm() {
    if (selected && selected > 0) {
      if (selectedTask && !addingTest && editing) {
        return <TaskForm onOkClick={handleFormOkClick} task={selectedTask} />;
      }
    } else if (creating) {
      return <TaskForm onOkClick={handleFormOkClick} />;
    }
  }

  const isSelected = (id) => selected === id;

  function mapBody(tasks) {
    if (tasks && tasks.length > 0) {
      const tableBody = tasks.map((task) => {
        const isItemSelected = isSelected(task.id);
        return (
          <Fragment key={task.id}>
            <TableRow
              key={task.id}
              aria-checked={isItemSelected}
              onClick={(event) => handleClick(event, task.id)}
              selected={isItemSelected}
            >
              <TableCell>{getDateBadge(task)}</TableCell>
              <TableCell>{task.title}</TableCell>
              <TableCell>{task.getType()}</TableCell>
              <TableCell>
                <Stack>{getEditButton(task)}</Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={8}
              >
                <Collapse in={isItemSelected} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    <Typography variant="h6" gutterBottom component="div">
                      Details
                    </Typography>
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow>
                          <TableCell>Id</TableCell>
                          <TableCell>Notes</TableCell>
                          <TableCell>Priority</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={task.id}>
                          <TableCell>{task.id}</TableCell>
                          <TableCell>{task.notes}</TableCell>
                          <TableCell>{getPriorityBadge(task)}</TableCell>
                          <TableCell>{task.getStatus()}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </Fragment>
        );
      });
      return tableBody;
    }
  }

  const title = 'Tasks';

  return (
    <>
      <HelmetTracker title="Tasks" />
      <Card mb={6}>
        <CardHeader
          action={
            <IconButton
              aria-label="settings"
              size="large"
              onClick={(event) => handleAddClick(event)}
            >
              <PlusCircle />
            </IconButton>
          }
          title={title}
        />
        <Paper>
          <TableWrapper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Due</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{mapBody(tasks)}</TableBody>
            </Table>
          </TableWrapper>
        </Paper>
        {showTaskForm()}
      </Card>
    </>
  );
}
