import React from 'react';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
} from '@mui/material';

import { blue } from '@mui/material/colors';

function SummarizedUser({ searchedUser, onClick, selected }) {

  return (
    <ListItemButton onClick={onClick} selected={selected}>
      <ListItemIcon>
        <Avatar sx={{ bgcolor: blue[500] }}>{searchedUser.id}</Avatar>
      </ListItemIcon>
      <ListItemText
        primary={searchedUser.displayName}
        secondary={searchedUser.email}
      />
    </ListItemButton>
  );
}

export default SummarizedUser;
