/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import classnames from 'classnames';
import {
  textField,
  textArea,
  boolField,
  objSelectorNoNone,
  intSelector,
} from '../../core/CoreForms';
import { Col, Image, Form, Button, ButtonToolbar } from 'react-bootstrap';
import ModalImage from 'react-modal-image';
import { LinkContainer } from 'react-router-bootstrap';
import _ from 'lodash';
/* eslint-enable no-unused-vars */

class TackForm extends Component {
  state = {
    id: this.props.tack ? this.props.tack.id : null,
    name: this.props.tack ? this.props.tack.name : '',
    description: this.props.tack ? this.props.tack.description : '',
    brandName: this.props.tack ? this.props.tack.brandName : '',
    color: this.props.tack ? this.props.tack.color : '',
    price: this.props.tack ? this.props.tack.price : 0.0,
    pricePaid: this.props.tack ? this.props.tack.pricePaid : 0.0,
    cost: this.props.tack ? this.props.tack.cost : 0.0,
    dataStatus: this.props.tack ? this.props.tack.dataStatus : 0,
    photos: this.props.tack ? this.props.tack.photos : [],
    // barnId: this.props.tack ? this.props.tack.barnId : 0,
    _tackCategory:
      this.props.tackCategories &&
      this.props.tackCategories[_.keys(this.props.tackCategories)[0]]
        ? this.props.tackCategories[_.keys(this.props.tackCategories)[0]].id
        : '',
    photoURL: this.props.tack
      ? this.props.tack.photoURL
      : '/images/tacks/tack_avatar.jpg',
    errors: '',
    loading: false,
  };

  // componentWillReceiveProps = (nextProps) => {
  //   if (nextProps.tack) {
  //     this.setState({
  //       id: nextProps.tack.id ? nextProps.tack.id : null,
  //       name: nextProps.tack.name,
  //       description: nextProps.tack.description,
  //       brandName: nextProps.tack.brandName,
  //       color: nextProps.tack.color,
  //       price: nextProps.tack.price,
  //       pricePaid: nextProps.tack.pricePaid,
  //       cost: nextProps.tack.cost,
  //       _tackCategory: nextProps.tack.tackCategoryId,
  //       visible: nextProps.tack.visible,
  //       dataStatus: nextProps.tack.dataStatus,
  //     });
  //   }
  // };

  componentDidUpdate = (nextProps) => {
    if (nextProps.tack) {
      this.setState({
        id: nextProps.tack.id ? nextProps.tack.id : null,
        name: nextProps.tack.name,
        description: nextProps.tack.description,
        brandName: nextProps.tack.brandName,
        color: nextProps.tack.color,
        price: nextProps.tack.price,
        pricePaid: nextProps.tack.pricePaid,
        cost: nextProps.tack.cost,
        _tackCategory: nextProps.tack.tackCategoryId,
        visible: nextProps.tack.visible,
        dataStatus: nextProps.tack.dataStatus,
      });
    }
  };

  handleChange = (e) => {
    if (e.target) {
      if (!!this.state.errors[e.target.id]) {
        //  gets a separate copy of errors
        let errors = Object.assign({}, this.state.errors);
        delete errors[e.target.id];
        this.setState({
          [e.target.id]: e.target.value,
          errors,
        });
      } else {
        this.setState({ [e.target.id]: e.target.value });
      }
    }
  };

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    // validation
    let errors = {};
    if (this.state.name === '') errors.name = 'Tack Name required!';
    this.setState({ errors });
    const isValid = Object.keys(errors).length === 0;

    if (isValid) {
      console.log('Cost');
      console.log(this.state.cost);
      const {
        id,
        name,
        description,
        brandName,
        color,
        price,
        pricePaid,
        cost,
        _tackCategory,
        visible,
        dataStatus,
      } = this.state;
      this.setState({ loading: true });
      this.props
        .saveTack({
          id,
          name,
          description,
          brandName,
          color,
          price,
          pricePaid,
          cost,
          _tackCategory,
          visible,
          dataStatus,
          userId: this.props.auth.id,
        })
        .catch((err) =>
          err.response
            .json()
            .then(({ errors }) => this.setState({ errors, loading: false }))
        );
    }
  };

  handleBoolChanged = (e) => {
    this.setState({ [e.target.id]: e.target.checked });
    if (e.target.id === 'oneDay') {
      if (e.target.checked) {
        this.setState({ endDate: this.state.startDate });
      }
    }
  };

  handleSelectChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  renderFormTitle() {
    if (this.state.id) {
      return (
        <div>
          <h1>Edit Tack</h1>
        </div>
      );
    } else {
      return (
        <div>
          <h1>Add Tack</h1>
        </div>
      );
    }
  }

  createOptionObjects() {
    return _.map(this.props.tackCategories, (tackCategory) => {
      return {
        index: tackCategory.id,
        label: tackCategory.name + ' (name)',
      };
    });
  }

  cancelButton() {
    return <Button variant="warning">Cancel</Button>;
  }

  saveButton() {
    return (
      <Button type="submit" variant="primary">
        Save
      </Button>
    );
  }

  okCancelButtons() {
    return (
      <ButtonToolbar>
        <LinkContainer to={'/tack'}>{this.cancelButton()}</LinkContainer>
        {this.saveButton()}
      </ButtonToolbar>
    );
  }

  renderImgTag(photo) {
    if (photo && photo.amazons3URLThumb && photo.amazons3URLThumb.length > 0) {
      return (
        <ModalImage
          small={photo.amazons3URLThumb}
          medium={photo.amazons3URLThumb}
          large={photo.amazons3URL}
          alt="Hello World!"
          key={photo.id}
        />
      );
    }
    if (photo && photo.amazons3URL && photo.amazons3URL.length > 0) {
      // <div style={{ maxWidth: "400px" }}>
      return (
        <ModalImage
          small={photo.amazons3URL}
          medium={photo.amazons3URL}
          alt="Hello World!"
          key={photo.id}
        />
      );
    } else {
      return (
        <Image src="/images/photos/blank.png" alt="Avatar" rounded responsive />
      );
    }
  }

  getTackPhoto() {
    if (this.state.photos && this.state.photos.length > 0) {
      return (
        <div>
          <div>
            <ModalImage
              small={this.state.photos[0].amazons3URLThumb}
              medium={this.state.photos[0].amazons3URLThumb}
              large={this.state.photos[0].amazons3URL}
              alt="Hello World!"
              key={this.state.photos[0].id}
            />
          </div>
        </div>
      );
    }
    return (
      <div>
        <div>
          <Image
            src="/images/tacks/tack_avatar_thumb.jpg"
            alt="Avatar"
            rounded
          />
        </div>
      </div>
    );
  }

  /* eslint-disable max-len */
  render() {
    const dataStatusTypes = [
      'OK',
      'New',
      'Hidden',
      'Deleted',
      'Unknown',
      'Archived',
    ];
    const form = (
      <Form
        className={classnames('ui', 'form', { loading: this.state.loading })}
        onSubmit={this.handleSubmit}
      >
        {this.renderFormTitle()}
        {!!this.state.errors.global && (
          <div className="ui negative message">
            <p>{this.state.errors.global}</p>
          </div>
        )}
        {console.log('loc1')}
        {textField('name', 'Tacks Name', this)}
        {console.log('loc2')}
        {textArea('description', 'Brief Description', this)}
        {textField('brandName', 'Brand Name', this)}
        {textField('color', 'Color', this)}
        {textField('price', 'Price', this)}
        {textField('pricePaid', 'Price Paid', this)}
        {textField('cost', 'Cost', this)} 
        { objSelectorNoNone(
          '_tackCategory',
          'Tack Category',
          this,
          this.createOptionObjects()
        )}
        {boolField('visible', 'Visible', this)}
        {intSelector('dataStatus', 'Data Status', this, dataStatusTypes)}
        <div className="field">{this.okCancelButtons('/tacks')}</div>
      </Form>
    );
    return (
      <div>
        <Col xs={12} sm={12} md={12}>
          {this.getTackPhoto()}
        </Col>
        <Col xs={12} sm={12} md={12}>
          {form}
        </Col>
      </div>
    );
  }
}

export default TackForm;
