import React from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import { Grid, List } from '@mui/material';
import _ from 'lodash';
import SummarizedBarn from './SummarizedBarn';

const SearchMain = styled(Grid)``;

const SearchMessages = styled.div`
  overflow-y: scroll;
  height: calc(65vh);
`;

function BarnUserPanel() {
  const [selected, setSelected] = React.useState(-1);
  const searchedUser = useSelector(
    (state) => state.returnedUsers.userSearch.userInfo
  );

  const barns = useSelector((state) => state.barns);

  const handleClick = (event, id) => {
    setSelected(id);
  };

  const isSelected = (id) => selected === id;

  function listBarns() {
    if (
      barns &&
      searchedUser  &&
      searchedUser.id
    ) {
      return barns.map((item, index) => {
        const isItemSelected = isSelected(item.id);
        var userBarn = null;
        if (searchedUser.barns) {
          userBarn = _.find(searchedUser.barns, {
            barnId: item.id,
          });
        }
        return (
          <SummarizedBarn
            barn={item}
            aria-checked={isItemSelected}
            key={index}
            onClick={(event) => handleClick(event, item.id)}
            selected={isItemSelected}
            userBarn={userBarn}
            userId={searchedUser.id}
          />
        );
      });
    }
  }

  return (
    <SearchMain item xs={12} md={8} lg={9}>
      <SearchMessages>
        {searchedUser ? searchedUser.displayName : 'no user'}
        <List>{listBarns()}</List>
      </SearchMessages>
    </SearchMain>
  );
}

export default BarnUserPanel;
