import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import EventDivisionStats from './EventDivisionStats';
import EntryStats from './EntryStats';
const _ = require('lodash');

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

const SchedulingHUD = ({ entries, eventDivisions, selectedEntry, selectedEntries }) => {
  function processDivisions() {
    if (eventDivisions) {
      return eventDivisions.map((element) => (
          <EventDivisionStats
            eventDivision={element}
            key={element.id}
            divisionEntries={_.filter(entries, function (o) {
              return o.eventDivisionId === parseInt(element.id);
            })}
          />
      ));
    }
  }

  function renderEntryStats() {
    if(selectedEntry && selectedEntries) {
      return <EntryStats entry={selectedEntry} entries={selectedEntries} />;
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
        Event DivisionsHUD
      <Grid container spacing={2}>
        {renderEntryStats()}
        {processDivisions()}
      </Grid>
    </Box>
  );
};

export default SchedulingHUD;
