/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Table, Button, Alert } from 'react-bootstrap';
import { ListCheck } from 'react-bootstrap-icons';
import HorseFeedRow from './core/HorseFeedRow';
import { LinkContainer } from 'react-router-bootstrap';
/* eslint-enable no-unused-vars */

class HorseFeed extends Component {
  tableHorses() {
    return _.values(this.props.horses).map((horse) => {
      return <HorseFeedRow horse={horse} key={horse.id} />;
    });
  }

  render() {
    return (
      <div>
        <Alert>
          <ListCheck />
          &nbsp;HorseFeed
          <br />
          <LinkContainer to={'/horses'}>
            <Button variant="primary">Return to Horses</Button>
          </LinkContainer>
        </Alert>
        <Table responsive striped bordered>
          <thead>
            <tr>
              <th>Type</th>
              <th>Description</th>
              <th>Barn</th>
            </tr>
          </thead>
          <tbody>{this.tableHorses()}</tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  horses: state.horses,
});

export default connect(mapStateToProps)(HorseFeed);
