import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Container, Button } from 'react-bootstrap';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SchedulingHUD from '../core/SchedulingHUD';

import ScheduleRing from '../core/ScheduleRing';

import { fetchRingsForEvent } from '../../../redux/actions/scheduler/ringActions';
import {
  fetchEntries,
  fetchEventDivisions,
} from '../../../redux/actions/Events/entryActions';

import { useDispatch, useSelector } from 'react-redux';
const _ = require('lodash');

export function SchedulingPage() {
  const dispatch = useDispatch();

  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedEntryList, setSelectedEntryList] = useState([]);

  useEffect(() => {
    dispatch(fetchEntries());
    dispatch(fetchEventDivisions());
    // dispatch(fetchBlocks(115));
    dispatch(fetchRingsForEvent(115));
  }, [dispatch]);

  // const ring = useSelector((state) => state.rings[1]);
  // const ring2 = useSelector((state) => state.rings[2]);
  const eventRings = useSelector((state) => state.rings);
  // const rings = useSelector((state) => state.rings);
  // const blocks = useSelector((state) => state.scheduleBlocks);
  const eventDivisions = useSelector((state) => state.eventDivisions);
  const entries = useSelector((state) => state.entries.dressage);

  function handleSelectEntry(entry) {
    if (selectedEntry) {
      selectedEntry.selected = false;
    }
    if (!selectedEntry || selectedEntry.id !== entry.id) {
      entry.selected = true;
      setSelectedEntry(entry);
      var riderArray = _.filter(entries, (entryItem) => {
        return entryItem.riderId === entry.riderId;
      });
      setSelectedEntryList(riderArray);
    } else {
      setSelectedEntry(null);
      setSelectedEntryList([]);
    }
  }

  function renderRing(ring) {
    if (ring && entries) {
      var entriesForRing = _.filter(entries, function (entry) {
        return (
          entry.rideTimes &&
          entry.rideTimes.dressage &&
          entry.rideTimes.dressage.ringId === parseInt(ring.id)
        );
      });
      return (
        <ScheduleRing
          startTime="October 13, 2023 08:00:00 AM"
          durationHours="5"
          ring={ring}
          entries={entriesForRing}
          onSelectEntry={handleSelectEntry}
          key={ring.id}
        />
      );
    }
  }

  const onSaveClick = () => {
    // var dressageEntries = _.filter(entries, function (entry) {
    //   return entry.rideTimes && entry.rideTimes.dressage;
    // });
    // console.log(dressageEntries);
  };


  function renderRingCards() {
    if (eventRings) {
      return eventRings.map((element) => {
        return (
          <Col xs={1} md={3} key={element.id}>
            {renderRing(element)}
          </Col>
        );
      });
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Container>
        <Row>
          <Button onClick={onSaveClick}>Save</Button>
          <Col xs={2} md={3}>
            <Card bg="dark">
              <Card.Header>
                <Card.Title>HUD</Card.Title>
              </Card.Header>
              <Card.Body>
                <SchedulingHUD
                  entries={entries}
                  eventDivisions={eventDivisions}
                  selectedEntry={selectedEntry}
                  selectedEntries={selectedEntryList}
                />
              </Card.Body>
            </Card>
          </Col>
          {renderRingCards()}
        </Row>
      </Container>
    </DndProvider>
  );
}

export default SchedulingPage;
