/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { Image } from 'react-bootstrap';
import ModalImage from 'react-modal-image';
/* eslint-enable no-unused-vars */

export default function TackTableRow({ tack }) {
  function clickLink() {
    if (tack) {
      return '/tack/manage/' + tack.id;
    }
    return '/tack';
  }

  function renderImgTag(photoURL) {
    if (photoURL && photoURL.length > 0) {
      return (
        <ModalImage
          small={photoURL}
          medium={photoURL}
          large={photoURL}
          alt="Hello World!"
          key={photoURL}
        />
      );
    } else {
      return (
        <Image src="/images/photos/blank.png" alt="Avatar" rounded responsive />
      );
    }
  }

  function getTackPhoto() {
    if (tack && tack.photoURL && tack.photoURL.length > 1) {
      return (
        <div>
          <div>{renderImgTag(tack.photoURL)}</div>
        </div>
      );
    }
    return (
      <div>
        <div>
          <Image
            src="/images/horses/horse_avatar_thumb.jpg"
            alt="Avatar"
            rounded
            responsive
          />
        </div>
      </div>
    );
  }

  return (
    <LinkContainer to={clickLink()}>
      <tr>
        <td>
          <h3>{getTackPhoto()}</h3>
        </td>
        <td>
          <h3>{tack.name}</h3>
        </td>
        <td>
          <h4>{tack.price}</h4>
        </td>
      </tr>
    </LinkContainer>
  );
}

TackTableRow.propTypes = {
  tack: PropTypes.object.isRequired,
};
