import axios from 'axios';
import { FETCH_USER } from './types';
import { API_CURRENT_USER, API_STRIPE_HANDLE_TOKEN } from '../../es5Routes';
import { fetchEvents } from './Events/eventActions';
import { fetchBarns, fetchBarnUsers } from './users/barnActions';
import { fetchHorses } from './horseActions';
import { fetchEventHorsesByUserId } from './Events/eventHorseActions';
import { fetchTacks } from './tackActions';
import { addFavoriteToUser } from './users/userActions';
import { fetchTackCategories } from './tackCategoriesActions';
import { fetchCommunityUsers } from './users/communityUserActions';
import { fetchMessagesByRecipientId } from './customers/messageActions';
import {
  fetchDailySalesReport,
  fetchWeeklySalesReport,
  fetchWeeklySalesTotals,
} from './oculus/reporting/reportingActions';
import {
  fetchCommunityInvites,
  fetchCommunityInvitesToMe,
} from './users/communityInviteActions';
import User from '../classes/User';
import { fetchEventTests } from './Events/entryActions';

function userDataToClass(userData) {
  if (userData) {
    return new User(
      userData.id,
      userData.email,
      userData.firstName,
      userData.lastName,
      userData.displayName,
      userData.cell,
      userData.authorized,
      userData.verified,
      userData.perms,
      userData.person, // singler person array
      userData.favorites
    );
  } else {
    return null;
  }
}

export const fetchUserOnly = () => async (dispatch) => {
  const res = await axios.get(API_CURRENT_USER);
  // console.log(res.data);
  dispatch({ type: FETCH_USER, payload: userDataToClass(res.data) });
};

export const fetchUser = () => async (dispatch) => {
  const res = await axios.get(API_CURRENT_USER);
  const user = userDataToClass(res.data);
  dispatch({ type: FETCH_USER, payload: user });
  if (res.data) {
    if (res.data.perms) {
      if (user.checkHasPermission('AEGON')) {
        dispatch(fetchEvents());
      }
      if (
        user.checkHasPermission('ADD_BARNS') ||
        user.checkHasPermission('ADD_HORSES')
      ) {
        dispatch(fetchBarns());
        dispatch(fetchBarnUsers());
        dispatch(fetchWeeklySalesReport());
        dispatch(fetchDailySalesReport());
        dispatch(fetchWeeklySalesTotals());
      }
      if (user.checkHasPermission('ADD_HORSES')) {
        dispatch(fetchHorses());
        dispatch(fetchTacks());
        dispatch(fetchTackCategories());
      }
    }
    dispatch(fetchCommunityUsers());
    dispatch(fetchCommunityInvites());
    dispatch(fetchMessagesByRecipientId(user.id));
    dispatch(fetchCommunityInvitesToMe());
    dispatch(fetchEventHorsesByUserId(user.id));
    dispatch(fetchEventTests());
  }
};

export const loginUser = (values, navigate) => async (dispatch) => {
  const res = await axios.post('/api/auth/login/', values);
  if (res && res.data && res.data === 'PASSWORD_INCORRECT') {
    navigate('/invalidlogin/pw');
  } else if (res && res.data && res.data === 'ACCOUNT_LOCKED') {
    navigate('/invalidlogin/locked');
  } else if (res && res.data && res.data === 'USER_NOT_FOUND') {
    navigate('/invalidlogin/user');
  } else {
    dispatch(fetchUser());
    if (values.source) {
      if (values.sourceId) {
        // const res = await axios.get(API_CURRENT_USER);
        dispatch(addFavoriteToUser({ horseId: values.sourceId }));
      }
      navigate('/' + values.source);
    } else {
      navigate('/user/home');
    }
  }
};

export const initiatePasswordReset = (values, history) => async (dispatch) => {
  await axios.post('/api/auth/startreset/', values);
  history.push('/resetsent');
};

export const resetPassword = (values, navigate) => async (dispatch) => {
  console.log(values);
  let response = await axios.post('/api/auth/reset/', values);
  console.log(response);
  if (response.data === 'PASSWORD_UPDATED') {
    navigate('/login');
  } else {
    navigate('/invalidtoken');
  }
};

export const logoutUser = async (dispatch) => {
  await axios.post('/api/auth/logout');
  dispatch({ type: FETCH_USER, payload: null });
};

export const handleStripeToken = (token) => async (dispatch) => {
  const res = await axios.post(API_STRIPE_HANDLE_TOKEN, token);
  dispatch({ type: FETCH_USER, payload: res.data });
};
