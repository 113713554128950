/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import classnames from 'classnames';
import { Col, Form, Card, Row } from 'react-bootstrap';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import {
  okCancelLinkButtons,
  textField,
  passwordField,
  errorField,
} from '../../core/CoreForms';
import { createUser } from '../../../redux/actions/users/userActions';
import _ from 'lodash';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter } from '../../withRouter';
/* eslint-enable no-unused-vars */

class CreateAccount extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkPassword = this.checkPassword.bind(this);

    this.state = {
      email: this.props.workOrder ? this.props.workOrder.email : '',
      password: this.props.workOrder ? this.props.workOrder.password : '',
      password_re: this.props.workOrder ? this.props.workOrder.password_re : '',
      firstName: this.props.workOrder ? this.props.workOrder.firstName : '',
      lastName: this.props.workOrder ? this.props.workOrder.lastName : '',
      //   photoBin: this.props.workOrder ?
      // this.props.workOrder.photoBin : uuidv4(),
      //   photos: this.props.workOrder ? this.props.workOrder.photos : [],
      errors: '',
      loading: false,
      password_has_error: false,
    };
  }

  checkPassword = () => {
    if (
      !this.state.password ||
      this.state.password !== this.state.password_re
    ) {
      this.setState({ password_has_error: true });
    } else {
      this.setState({ password_has_error: false });
    }
  };

  handleChange = (e) => {
    if (e.target) {
      if (!!this.state.errors[e.target.id]) {
        //  gets a separate copy of errors
        let errors = Object.assign({}, this.state.errors);
        delete errors[e.target.id];
        this.setState({
          [e.target.id]: e.target.value,
          errors,
        });
      } else {
        this.setState({ [e.target.id]: e.target.value });
      }
      if (
        e.target.id === 'password_re' ||
        (e.target.id === 'password' && this.state.password_re !== '')
      ) {
        this.checkPassword();
      }
    }
  };

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    // validation
    let errors = {};
    if (this.state.email === '') {
      errors.email = 'Email required!';
    }
    if (this.state.password === '') {
      errors.password = 'Password required';
    }
    if (this.state.password_re === '') {
      errors.password_re = 'Password confirmation required';
    }
    if (this.state.firstName === '') {
      errors.firstName = 'First Name required';
    }
    if (this.state.lastName === '') {
      errors.lastName = 'Last Name required';
    }
    if (this.state.password !== this.state.password_re) {
      errors.password_re = 'Passwords do not match';
    }
    this.setState({ errors });
    const isValid = Object.keys(errors).length === 0;

    if (isValid) {
      const { email, password, firstName, lastName } = this.state;
      this.setState({ loading: true });
      this.props.createUser(
        {
          email: email.replace(/\s+/g, " ").trim(),
          password,
          firstName: firstName.replace(/\s+/g, " ").trim(),
          lastName: lastName.replace(/\s+/g, " ").trim(),
        },
        this.props.router.navigate
      );
    }
  };

  /* eslint-disable max-len */
  render() {
    const form = (
      <Card variant="danger">
        <Card.Header>
          <h2>Create Account</h2>
          <LinkContainer to={'/login'}>
            <Button variant="info">Back to Login</Button>
          </LinkContainer>
        </Card.Header>
        <Card.Body>
          <Form
            className={classnames('ui', 'form', {
              loading: this.state.loading,
            })}
            onSubmit={this.handleSubmit}
          >
            {textField('email', 'Email', this)}
            {errorField(this.state.errors.email)}
            {passwordField('password', 'Password', this)}
            {errorField(this.state.errors.password)}
            {passwordField('password_re', 'Confirm Password', this)}
            {errorField(this.state.errors.password_re)}
            {textField('firstName', 'First Name', this)}
            {errorField(this.state.errors.firstName)}
            {textField('lastName', 'Last Name', this)}
            {errorField(this.state.errors.lastName)}
            {okCancelLinkButtons('/login')}
          </Form>
        </Card.Body>
      </Card>
    );
    return (
      <div>
        <Row className="show-grid">
          <Col xs={12} sm={12} md={12}>
            {form}
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(null, { createUser })(withRouter(CreateAccount));
