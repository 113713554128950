import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPermissions } from '../../../../redux/actions/admin/permissionActions';
import PermissionsTable from '../core/PermissionsTable';
import HelmetTracker from '../../../core/HelmetTracker';
import UserPermissionEditor from '../../core/UserPermissionEditor';

export function PermissionsPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPermissions());
  }, [dispatch]);

  const permissions = useSelector((state) => state.permissions);

  return (
    <>
      <HelmetTracker title="Permissions" />
      <PermissionsTable permissions={permissions} />
      <UserPermissionEditor />
    </>
  );
}

export default PermissionsPage;
