import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  fetchJump,
  createJump,
  updateJump,
} from '../../../../redux/actions/jumpActions';
import JumpSubForm from '../core/JumpSubForm';
import { useNavigate, useParams } from 'react-router-dom';
import HelmetTracker from '../../../core/HelmetTracker';

export default function JumpForm() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      document.title = 'Edit Jump';
      dispatch(fetchJump(id));
    } else {
      document.title = 'Add Jump';
    }
  }, [dispatch, id]);

  const jump = useSelector(
    (state) =>
      state.jumps.find((m) => {
        return m.id === parseInt(id);
      }),
    shallowEqual
  );
  const auth = useSelector((state) => state.auth);

  function navigateBack() {
    if (id) navigate('/jumps/' + id);
    else navigate('/jumps');
  }

  function saveJump(jump) {
    if (jump && jump.id) {
      return dispatch(updateJump(jump)).then(() => {
        navigateBack();
      });
    } else {
      return dispatch(createJump(jump)).then(() => {
        navigateBack();
      });
    }
  }

  const userId = auth ? auth.id : null;
  const title = jump ? 'Edit Jump' : 'Add Jump';
  const objId = jump ? jump.id : null;

  return (
    <>
      <HelmetTracker title={title} objId={objId} userId={userId} />
      <JumpSubForm
        jump={jump}
        submitJump={saveJump}
        auth={auth}
      />
    </>
  );
}
