import React from 'react';
import HorseVideosGroup from './HorseVideosGroup';

export default function HorsePedigree({ horse }) {
  return (
    <div>
      {horse && horse.videos ? (
        <HorseVideosGroup horse={horse} />
      ) : (
        'No videos listed.'
      )}
    </div>
  );
}
