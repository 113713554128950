import { USERS_SEARCHED, USER_FETCHED } from '../../actions/types';

const INITIAL_STATE = {
  timestamp: new Date(),
  searchedUsers: [],
  searchString: '',
  userSearch: { timestamp: new Date(), userInfo: {} },
};

export default function reduce(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USERS_SEARCHED:
      if (state.timestamp.getTime() < action.timestamp.getTime()) {
        return {
          timestamp: action.timestamp,
          searchedUsers: action.searchedUsers,
          searchString: action.searchString,
          userSearch: { timestamp: new Date(), userInfo: {} },
        };
      }
      return state;
    case USER_FETCHED:
      if (state.userSearch.timestamp.getTime() < action.timestamp.getTime()) {
        const userVar = {
          timestamp: action.timestamp,
          userInfo: action.searchedUser,
        };
        return {
          timestamp: state.timestamp,
          searchedUsers: state.searchedUsers,
          searchString: state.searchString,
          userSearch: userVar,
        };
      }
      return state;
    default:
      return state;
  }
}
