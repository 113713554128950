import React, { Fragment, useEffect } from 'react';
import styled from '@emotion/styled';
import { PlusCircle, Edit } from 'react-feather';

import { fetchJumps } from '../../../../redux/actions/jumpActions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import JumpPhotosGroup from '../core/JumpPhotosGroup';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import HelmetTracker from '../../../core/HelmetTracker';

import {
  Badge, 
  Box,
  Button,
  Card as MuiCard,
  CardHeader,
  Collapse,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import Select from '@mui/material/Select';
import { spacing } from '@mui/system';
import _ from 'lodash';

const Card = styled(MuiCard)(spacing);

const theme = createTheme({
  palette: {
    intermediate: {
      main: '#ff0000', // Your custom color
      contrastText: '#f8f8f8', // Text color for contrast
    },
    preliminary: {
      main: '#248f24', // Your custom color
      contrastText: '#f8f8f8', // Text color for contrast
    },
    training: {
      main: '#000000', // Your custom color
      contrastText: '#f8f8f8', // Text color for contrast
    },
    novice: {
      main: '#f8f8f8', // Your custom color
      contrastText: '#000000', // Text color for contrast
    },
    beginnerNovice: {
      main: '#ffff00', // Your custom color
      contrastText: '#000000', // Text color for contrast
    },
    starter: {
      main: '#0099ff', // Your custom color
      contrastText: '#f8f8f8', // Text color for contrast
    },
    amoeba: {
      main: '#000080', // Your custom color
      contrastText: '#f8f8f8', // Text color for contrast
    },
  },
});

const Chip = styled(MuiChip)`
  height: 40px;
  padding: 4px 0;
  font-size: 120%;
  font-weight: 500;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : 'primary']};
`;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

function JumpsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedJumpId, setSelectedJumpId] = React.useState(-1);
  const [showPhotos, setShowPhotos] = React.useState(false);

  const [selectedLevel, setSelectedLevel] = React.useState(0);
  const [selectedCategory, setSelectedCategory] = React.useState(0);
  const [sortById, setSortById] = React.useState(false);

  useEffect(() => {
    dispatch(fetchJumps());
  }, [dispatch]);

  const auth = useSelector((state) => state.auth);


  const jumps = useSelector((state) => state.jumps);
  // const jumps = useSelector((state) => state.jumps);

  function getJumpsInitialSort() {
    if (sortById) {
      return [...jumps].sort( // otherwise this mutates jumps
        (a, b) =>
          a.id - b.id
      );
    } else {
      return jumps;
    }
  }

  function getJumpCountForLevel(level) {
    if (level === 0) {
      return jumps.length;
    } else {
      return filterJumpsByLevel(level).length;
    }
  }

  function filterJumpsByLevel(levelToFilter) {
    const initialJumps = getJumpsInitialSort();
    if (levelToFilter > 0) {
      var jumpList = _.filter(initialJumps, function (o) {
        return o.jumpLevel === levelToFilter;
      });
      return jumpList;
    } else {
      return initialJumps;
    }
  }

  function getJumpCountForCategory(category) {
    let count = filterJumpsByCategory(category).length;
    if (count > 0) return '(' + count + ')';
  }

  function filterJumpsByCategory(categoryToFilter) {
    var filteredJumps = filterJumpsByLevel(selectedLevel);
    if (categoryToFilter > 0) {
      var jumpList = _.filter(filteredJumps, function (o) {
        return o.type === categoryToFilter;
      });
      return jumpList;
    } else {
      return filteredJumps;
    }
  }

  function filterJumps() {
    let filteredJumps = filterJumpsByLevel(selectedLevel);

    if (selectedCategory > 0) {
      var jumpList = _.filter(filteredJumps, function (o) {
        return o.type === selectedCategory;
      });
      return jumpList;
    } else {
      return filteredJumps;
    }
  }

  function textChip(level, text) {
    var color = 'starter';
    if (level === 'Intermediate') {
      color = 'intermediate';
    }
    if (level === 'Preliminary') {
      color = 'preliminary';
    }
    if (level === 'Training') {
      color = 'training';
    }
    if (level === 'Novice') {
      color = 'novice';
    }
    if (level === 'Beginner Novice') {
      color = 'beginnerNovice';
    }
    return (
      <ThemeProvider theme={theme}>
        <Chip label={text} color={color} />
      </ThemeProvider>
    );
  }

  function jumpLevelChip(jump) {
    return textChip(jump.level, jump.level);
  }

  function isNewPhotoSystem(jump) {
    return jump.photos && jump.photos.length > 0;
  }

  function idChip(jump) {
    var idText = jump.id + '*';
    if (isNewPhotoSystem(jump)) {
      idText = jump.id;
    }
    return textChip(jump.level, idText);
  }

  function levelChip(jumpLevel) {
    var color = 'amoeba';
    var levelLabel = 'Amoeba';
    if (jumpLevel === 1) {
      color = 'intermediate';
      levelLabel = 'Intermediate';
    }
    if (jumpLevel === 2) {
      color = 'preliminary';
      levelLabel = 'Preliminary';
    }
    if (jumpLevel === 4) {
      color = 'training';
      levelLabel = 'Training';
    }
    if (jumpLevel === 5) {
      color = 'novice';
      levelLabel = 'Novice';
    }
    if (jumpLevel === 6) {
      color = 'beginnerNovice';
      levelLabel = 'Beginner Novice';
    }
    if (jumpLevel === 7) {
      color = 'starter';
      levelLabel = 'Starter';
    }
    levelLabel = levelLabel + ' (' + getJumpCountForLevel(jumpLevel) + ')';
    return (
      <ThemeProvider theme={theme}>
        <Chip label={levelLabel} color={color} />
      </ThemeProvider>
    );
  }

  const handleClick = (event, id) => {
    if (selectedJumpId !== id) {
      setSelectedJumpId(id);
      if (!showPhotos) {
        setShowPhotos(true);
      }
    } else {
      setShowPhotos(!showPhotos);
    }
  };

  const handleEditClick = (event, id) => {
    navigate('/jumps/' + id);
  };

  const handleAddClick = (event) => {
    navigate('/jumps/add/');
  };

  function getEditButton(jump) {
    if (auth)
      return (
        <Button
          aria-label="editJump"
          onClick={(event) => handleEditClick(event, jump.id)}
          variant="outlined"
          color="success"
          endIcon={<Edit />}
        >
          Edit
        </Button>
      );
  }

  const handleLevelChange = (event) => {
    setSelectedLevel(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  function getLevelSelector() {
    return (
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Level</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedLevel}
          label="Level"
          onChange={handleLevelChange}
        >
          <MenuItem value={0}>All</MenuItem>
          <MenuItem value={1}>{levelChip(1)}</MenuItem>
          <MenuItem value={2}>{levelChip(2)}</MenuItem>
          {/* <MenuItem value={3}>{levelChip(3)}</MenuItem> //Modified */}
          <MenuItem value={4}>{levelChip(4)}</MenuItem>
          <MenuItem value={5}>{levelChip(5)}</MenuItem>
          <MenuItem value={6}>{levelChip(6)}</MenuItem>
          <MenuItem value={7}>{levelChip(7)}</MenuItem>
          <MenuItem value={8}>{levelChip(8)}</MenuItem>
          {/* <MenuItem value={9}>{levelChip(9)}</MenuItem> //Multiple */}
        </Select>
      </FormControl>
    );
  }

  function getCategoryMenuItem(name, level) {
    return getJumpCountForCategory(level) || selectedLevel === 0 ? (
      <MenuItem value={level} disabled={!getJumpCountForCategory(level)}>
        {name} {getJumpCountForCategory(level)}
      </MenuItem>
    ) : (
      ''
    );
  }

  function getCategorySelector() {
    return (
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Category</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedCategory}
          label="Category"
          onChange={handleCategoryChange}
        >
          <MenuItem value={0}>All {getJumpCountForCategory(0)}</MenuItem>
          {getCategoryMenuItem('Bank', 1)}
          {getCategoryMenuItem('Barn/Cabin', 2)}
          {getCategoryMenuItem('Brush', 3)}
          {getCategoryMenuItem('Coop', 4)}
          {getCategoryMenuItem('Corner', 5)}
          {getCategoryMenuItem('Ditch', 6)}
          {getCategoryMenuItem('Ditch & Wall', 7)}
          {getCategoryMenuItem('Feeder', 8)}
          {getCategoryMenuItem('Hanging Log', 9)}
          {getCategoryMenuItem('Log', 10)}
          {getCategoryMenuItem('Log Box', 11)}
          {getCategoryMenuItem('Ramp', 12)}
          {getCategoryMenuItem('Rolltop', 13)}
          {getCategoryMenuItem('Scoop', 14)}
          {getCategoryMenuItem('Table', 15)}
          {getCategoryMenuItem('Trakhener', 16)}
          {getCategoryMenuItem('Triple Brush', 17)}
          {getCategoryMenuItem('Water', 18)}
        </Select>
      </FormControl>
    );
  }

  // For multi row select
  //   const isSelected = (id) => selected.indexOf(id) !== -1;

  // Single select
  const isSelected = (id) => selectedJumpId === id;

  function mapBody(jumps) {
    if (jumps && jumps.length > 0) {
      const tableBody = jumps.map((jump) => {
        const isItemSelected = isSelected(jump.id);
        return (
          <Fragment key={jump.id}>
            <TableRow
              key={jump.id}
              aria-checked={isItemSelected}
              onClick={(event) => handleClick(event, jump.id)}
              selected={isItemSelected}
            >
              <TableCell>{idChip(jump)}</TableCell>
              <TableCell><Badge variant="outlined"  color="primary" badgeContent={jump.photos ? jump.photos.length : 0} >{getJumpPhoto(jump)}</Badge></TableCell>
              <TableCell>
                <Stack>
                  {jumpLevelChip(jump)}
                  {getEditButton(jump)}
                </Stack>
              </TableCell>
              <TableCell>{jump.category}</TableCell>
              <TableCell>{jump.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={4}
              >
                <Collapse
                  in={isItemSelected && showPhotos}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ margin: 5 }}>
                    {jump && jump.photos && jump.photos.length > 0 ? (
                      <JumpPhotosGroup jump={jump} />
                    ) : (
                      ''
                    )}
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </Fragment>
        );
      });
      return tableBody;
    }
  }

  function renderImgTag(photoURL, jump) {
    if (photoURL && photoURL.length > 0) {
      return <Box component="img" src={photoURL} alt="Avatar" height="100px" />;
    } else {
      return (
        <Box
          component="img"
          src="/images/photos/blank.png"
          alt="Avatar"
          height="100px"
        />
      );
    }
  }

  function getJumpPhoto(jump) {
    if (jump && jump.hasPhoto()) {
      return renderImgTag(jump.getMainPhotoURL(), jump);
    }
    return (
      <div>
        <div>
          <Box
            component="img"
            src="/images/horses/horse_avatar_thumb.jpg"
            alt="Avatar"
          />
        </div>
      </div>
    );
  }

  return (
    <Card mb={6}>
      <HelmetTracker title="Jumps" />
      {auth ? (
        <CardHeader
          action={
            <IconButton
              aria-label="settings"
              size="large"
              onClick={(event) => handleAddClick(event)}
            >
              <PlusCircle />
            </IconButton>
          }
          title="Jumps"
        />
      ) : (
        ''
      )}
      <Paper>
        <TableWrapper>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setSortById(!sortById);
                    }}
                  >
                    Id
                  </Button>
                </TableCell>
                <TableCell>Photo</TableCell>
                <TableCell>{getLevelSelector()} </TableCell>
                <TableCell>{getCategorySelector()}</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{mapBody(filterJumps())}</TableBody>
          </Table>
        </TableWrapper>
      </Paper>
    </Card>
  );
}

export default JumpsPage;
