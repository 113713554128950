/* eslint-disable no-unused-vars */
import React from 'react';
import { ListGroupItem, Card, Button, ListGroup, Image } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import {
  Grid,
  // Divider as MuiDivider,
  // Typography as MuiTypography,
  Stack,
} from '@mui/material';

export default function JumpDetailsPanel({ jump }) {

  function getDescription() {
    if (jump) {
      return jump.description;
    }
    return '';
  }

  function getGeneralInformation() {
    if (jump) {
      return (  
        <ListGroup>
          <ListGroupItem>Description: {getDescription()}</ListGroupItem>
          <ListGroupItem>Height: {jump.height}</ListGroupItem>
          <ListGroupItem>Width: {jump.width}</ListGroupItem>
          <ListGroupItem>Base Spread: {jump.baseSpread}</ListGroupItem>
          <ListGroupItem>
            <Image src={jump.getMainPhotoURLHalf()} width="200px" />
          </ListGroupItem>
        </ListGroup>
      );
    }
    return <ListGroup></ListGroup>;
  }

  function getEditButton() {
    var route = '';
    if (jump && jump.id) route = '/jumps/edit/' + jump.id;
    return (
      <LinkContainer to={route}>
        <Button variant="danger">Edit</Button>
      </LinkContainer>
    );
  }

  

  return (
    <Card bg="primary">
      <Card.Header>
        <h2>Details &nbsp;&nbsp;{getEditButton()}</h2>
      </Card.Header>
      <Card.Body>{getGeneralInformation()}</Card.Body>
    </Card>
  );
}
