import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Button } from 'react-bootstrap';
import { HandThumbsUp } from 'react-bootstrap-icons';
import { LinkContainer } from 'react-router-bootstrap';

export default function AddMedia() {
  function checkHasPermission(auth, permission) {
    if (auth && auth.perms) {
      return auth.perms.findIndex((x) => x.permission === permission) > -1;
    }
    return false;
  }

  const hasCreateMedia = useSelector((state) => checkHasPermission(state.auth, 'CREATE_MEDIA'));

  function displayAddMedia() {
    if (hasCreateMedia) {
      return (
        <Card.Body>
          <LinkContainer to="/media/new">
            <Button variant="dark" aria-label="Add">
              <HandThumbsUp />
              &nbsp;&nbsp;Add Media
            </Button>
          </LinkContainer>
        </Card.Body>
      );
    }
  }

  return <>{displayAddMedia()}</>;
}
