/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import LoginFormik from '../../formik/LoginFormik';
/* eslint-enable no-unused-vars */

class LoginInvalid extends Component {
  getTitle = () => {
    console.log(this.props);
    if (this.props.match.params) {
      if (this.props.match.params.reason === 'pw') {
        return 'Invalid Password, Please Try Again';
      }
      if (this.props.match.params.reason === 'user') {
        return 'Email/User Not Found, Please Try Again';
      }
    }
    return 'Login to System';
  };

  render() {
    const form = (
      <Card variant="success">
        <Card.Header>
          <Card.Title    >{this.getTitle()}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Card variant="warning">
            <Card.Header>
              <Card.Title    >Local Login</Card.Title>
            </Card.Header>
            <Card.Body>
              <LoginFormik />
            </Card.Body>
            <Card.Footer>
              <h2>Never logged in?</h2>
              <LinkContainer to={'/createaccount'}>
                <Button variant="info">Create Account</Button>
              </LinkContainer>
            </Card.Footer>
          </Card>
        </Card.Body>
        <Card.Footer>
          <h2>Need to reset your password?</h2>
          <LinkContainer to={'/initiateresetpass'}>
            <Button variant="danger">Reset Password</Button>
          </LinkContainer>
        </Card.Footer>
      </Card>
    );
    return (
      <Container>
        <Row className="show-grid">
          <Col xs={12} sm={12} md={12}>
            {form}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default LoginInvalid;
