import React from 'react';
import { Form } from 'react-final-form';
import {
  okCancel,
  renderFormTitle,
  finalTextField,
  finalObjSelector,
  finalBoolField,
} from '../../../core/FinalCoreForms';
import { useNavigate } from 'react-router-dom';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function JumpSubForm({ jump, submitJump }) {
  const navigate = useNavigate();

  const initialValues = {
    id: jump && jump.id ? jump.id : null,
    jumpNumber: jump && jump.jumpNumber ? jump.jumpNumber : 0,
    description: jump && jump.description ? jump.description : '',
    category: jump && jump.category ? jump.category : '',
    level: jump && jump.level ? jump.level : '',
    jumpLevel: jump && jump.jumpLevel ? jump.jumpLevel : 0,
    type: jump && jump.type ? jump.type : 0,
    jumpCondition: jump && jump.jumpCondition ? jump.jumpCondition : 0,
    notes: jump && jump.notes ? jump.notes : '',
    color: jump && jump.color ? jump.color : '',
    skinny: jump && jump.skinny ? jump.skinny > 0 : false,
    height: jump && jump.height ? jump.height : 0,
    width: jump && jump.width ? jump.width : 0,
    baseSpread: jump && jump.baseSpread ? jump.baseSpread : 0,
    dataStatus: jump && jump.dataStatus ? jump.dataStatus : 0,
  };

  const onSubmit = async (values) => {
    await sleep(300);
    if (jump && submitJump) {
      Object.assign(jump, values);
      submitJump(jump);
    } else {
      let newData = {};
      Object.assign(newData, values);
      submitJump(newData);
    }
  };

  function handleCancelClick() {
    navigate(-1);
  }


  const jumpLevelOptionsList = [
    { key: 'None', value: '0' },
    { key: 'Intermediate', value: '1' },
    { key: 'Preliminary', value: '2' },
    { key: 'Modified', value: '3' },
    { key: 'Training', value: '4' },
    { key: 'Novice', value: '5' },
    { key: 'Beginner Novice', value: '6' },
    { key: 'Starter', value: '7' },
    { key: 'Amoeba', value: '8' },
    { key: 'Multiple', value: '9' },
  ];


  const typeOptionsList = [
    { key: 'None', value: '0' },
    { key: 'Bank', value: '1' },
    { key: 'Barn/Cabin', value: '2' },
    { key: 'Brush', value: '3' },
    { key: 'Coop', value: '4' },
    { key: 'Corner', value: '5' },
    { key: 'Ditch', value: '6' },
    { key: 'Ditch & Wall', value: '7' },
    { key: 'Feeder', value: '8' },
    { key: 'Hanging Log', value: '9' },
    { key: 'Log', value: '10' },
    { key: 'Log Box', value: '11' },
    { key: 'Ramp', value: '12' },
    { key: 'Rolltop', value: '13' },
    { key: 'Scoop', value: '14' },
    { key: 'Table', value: '15' },
    { key: 'Trakhener', value: '16' },
    { key: 'Triple Brush', value: '17' },
    { key: 'Water', value: '18' },
  ];


  const jumpConditionOptionsList = [
    { key: 'None', value: '0' },
    { key: 'New', value: '1' },
    { key: 'Excellent', value: '2' },
    { key: 'Good', value: '3' },
    { key: 'Fair', value: '4' },
    { key: 'Poor', value: '5' },
    { key: 'Disabled', value: '6' },
  ];


  const dataStatusOptionsList = [
    { key: 'Ok', value: '0' },
    { key: 'New', value: '1' },
    { key: 'Unknown', value: '2' },
    { key: 'Deleted', value: '3' },
    { key: 'Unknown', value: '4' },
    { key: 'Archived', value: '5' },
  ];


  return (
    <div>
      {renderFormTitle(jump, 'jumps')}

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            {okCancel(handleCancelClick)}

            {finalTextField('jumpNumber', 'Jump Number')}
            {finalTextField('description', 'Description')}
            {finalTextField('category', 'Category')}
            {finalTextField('level', 'Level')}
            {finalObjSelector('jumpLevel', 'Jump Level', jumpLevelOptionsList)}
            {finalObjSelector('type', 'Type', typeOptionsList)}
            {finalObjSelector('jumpCondition', 'Jump Condition', jumpConditionOptionsList)}
            {finalTextField('notes', 'Notes')}
            {finalTextField('color', 'Color')}
            {finalBoolField('skinny', 'Skinny')}
            {finalTextField('height', 'Height')}
            {finalTextField('width', 'Width')}
            {finalTextField('baseSpread', 'Base Spread')}
            {finalObjSelector('dataStatus', 'Data Status', dataStatusOptionsList)}

            {okCancel(handleCancelClick)}
            <pre>{JSON.stringify(values, 0, 2)}</pre>
          </form>
        )}
      />
    </div>
  );
}