export const FETCH_USER = 'fetch_user';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const FETCH_SURVEYS = 'fetch_surveys';
export const FETCH_SURVEY = 'fetch_survey';
export const UPDATE_SURVEY = 'update_survey';

export const USER_INFO_FETCHED = 'USER_INFO_FETCHED';

export const ADD_HORSE = 'ADD_HORSE';
export const SET_HORSES = 'SET_HORSES';
export const HORSE_FETCHED = 'HORSE_FETCHED';
export const SALE_HORSE_FETCHED = 'SALE_HORSE_FETCHED';

export const SET_SALE_TACKS = 'SET_SALE_TACKS';
export const SALE_TACK_FETCHED = 'SALE_TACK_FETCHED';

export const SET_HORSE_DOCUMENTS = 'SET_HORSE_DOCUMENTS';
export const HORSE_DOCUMENT_FETCHED = 'HORSE_DOCUMENT_FETCHED';
export const SET_HORSE_PHOTOS = 'SET_HORSE_PHOTOS';
export const HORSE_PHOTO_FETCHED = 'HORSE_PHOTO_FETCHED';
export const SET_HORSE_VIDEOS = 'SET_HORSE_VIDEOS';
export const HORSE_VIDEO_FETCHED = 'HORSE_VIDEO_FETCHED';

export const SET_HORSE_INTERESTS = 'SET_HORSE_INTERESTS';
export const HORSE_INTEREST_FETCHED = 'HORSE_INTEREST_FETCHED';

export const ADD_TRAINER = 'ADD_TRAINER';
export const SET_TRAINERS = 'SET_TRAINERS';
export const TRAINER_FETCHED = 'TRAINER_FETCHED';
export const SALE_TRAINER_FETCHED = 'SALE_TRAINER_FETCHED';

export const SET_TRAINER_DOCUMENTS = 'SET_TRAINER_DOCUMENTS';
export const TRAINER_DOCUMENT_FETCHED = 'TRAINER_DOCUMENT_FETCHED';
export const SET_TRAINER_PHOTOS = 'SET_TRAINER_PHOTOS';
export const TRAINER_PHOTO_FETCHED = 'TRAINER_PHOTO_FETCHED';
export const SET_TRAINER_VIDEOS = 'SET_TRAINER_VIDEOS';
export const TRAINER_VIDEO_FETCHED = 'TRAINER_VIDEO_FETCHED';

export const MEDIA_FEED_FETCHED = 'MEDIA_FEED_FETCHED';
export const SET_MEDIA_FEED = 'SET_MEDIA_FEED';

export const MEDIA_FETCHED = 'MEDIA_FETCHED';
export const SET_MEDIAS = 'SET_MEDIAS';

export const SET_COMMUNITIES = 'SET_COMMUNITIES';
export const COMMUNITY_FETCHED = 'COMMUNITY_FETCHED';

export const SET_COMMUNITY_USERS = 'SET_COMMUNITY_USERS';
export const COMMUNITY_USER_FETCHED = 'COMMUNITY_USER_FETCHED';

export const SET_COMMUNITY_INVITES_TO_ME = 'SET_COMMUNITY_INVITES_TO_ME';
export const COMMUNITY_INVITE_TO_ME_FETCHED = 'COMMUNITY_INVITE_TO_ME_FETCHED';

export const SET_COMMUNITY_INVITES = 'SET_COMMUNITY_INVITES';
export const COMMUNITY_INVITE_FETCHED = 'COMMUNITY_INVITE_FETCHED';

export const SALE_HORSE_VIDEO_FETCHED = 'SALE_HORSE_FETCHED';
export const SET_SALE_HORSES = 'SET_SALE_HORSES';

export const SET_ADMIN_BARNS = 'SET_ADMIN_BARNS';
export const ADMIN_BARN_FETCHED = 'ADMIN_BARN_FETCHED';
export const ADMIN_BARN_UPDATED = 'ADMIN_BARN_UPDATED';

export const ADD_EVENT = 'ADD_EVENT';
export const EVENT_FETCHED = 'EVENT_FETCHED';
export const EVENT_CREATED = 'EVENT_CREATED';
export const EVENTS_FETCHED = 'EVENTS_FETCHED';
export const EVENT_UPDATED = 'EVENT_UPDATED';
export const SET_EVENTS = 'SET_EVENTS';
export const EVENT_DOCUMENT_FETCHED = 'EVENT_DOCUMENT_FETCHED';
export const SET_EVENT_DOCUMENTS = 'SET_EVENT_DOCUMENTS'; 

export const SET_CALENDAR = 'SET_CALENDAR'; 
export const CALENDAR_EVENT_FETCHED = 'CALENDAR_EVENT_FETCHED'; 

export const SET_BARNS = 'SET_BARNS';
export const BARN_FETCHED = 'BARN_FETCHED';

export const CLEAR_BARN_USERS = 'CLEAR_BARN_USERS';
export const SET_BARN_USERS = 'SET_BARN_USERS';
export const BARN_USER_FETCHED = 'BARN_USER_FETCHED';

export const SET_JUMPS = 'SET_JUMPS';
export const JUMP_UPDATED = 'JUMP_UPDATED';
export const JUMP_FETCHED = 'JUMP_FETCHED';  

export const ADD_TACK = 'ADD_TACK';
export const TACK_FETCHED = 'TACK_FETCHED';
export const SET_TACK = 'SET_TACK';
export const SET_TACK_PHOTOS = 'SET_TACK_PHOTOS';
export const TACK_PHOTO_FETCHED = 'TACK_PHOTO_FETCHED';

export const ADD_TACK_CATEGORY = 'ADD_TACK_CATEGORY';
export const SET_TACK_CATEGORIES = 'SET_TACK_CATEGORIES';
export const TACK_CATEGORY_UPDATED = 'TACK_CATEGORY_UPDATED';
export const TACK_CATEGORY_FETCHED = 'TACK_CATEGORY_FETCHED';

export const DIVISIONS_FETCHED = 'DIVISIONS_FETCHED';
export const DIVISION_FETCHED = 'DIVISION_FETCHED';
export const DIVISION_CREATED = 'DIVISION_CREATED';
export const DIVISION_UPDATED = 'DIVISION_UPDATED';

export const EVENT_DIVISIONS_FETCHED = 'EVENT_DIVISIONS_FETCHED';
export const EVENT_DIVISION_FETCHED = 'EVENT_DIVISION_FETCHED';
export const EVENT_DIVISION_CREATED = 'EVENT_DIVISION_CREATED';
export const EVENT_DIVISION_UPDATED = 'EVENT_DIVISION_UPDATED';

export const EVENT_TESTS_FETCHED = 'EVENT_TESTS_FETCHED';
export const EVENT_TEST_FETCHED = 'EVENT_TEST_FETCHED';
export const EVENT_TEST_CREATED = 'EVENT_TEST_CREATED';
export const EVENT_TEST_UPDATED = 'EVENT_TEST_UPDATED';

export const ENTRIES_FETCHED = 'ENTRIES_FETCHED';
export const ENTRY_CREATED = 'EVENT_TEST_UPDATED';
export const ENTRY_UPDATED = 'ENTRY_UPDATED';

export const EVENT_LEVELS_FETCHED = 'EVENT_LEVELS_FETCHED';

export const SET_EVENT_HORSES = 'SET_EVENT_HORSES';
export const EVENT_HORSE_FETCHED = 'EVENT_HORSE_FETCHED';

export const SET_EVENT_SELECTABLES = 'SET_EVENT_SELECTABLES';

export const USER_ENTRIES_FETCHED = 'USER_ENTRIES_FETCHED';
export const USER_ENTRY_FETCHED = 'USER_ENTRY_FETCHED';

// STORE 
export const SET_PRODUCT_CATEGORIES = 'SET_PRODUCT_CATEGORIES';
export const PRODUCT_CATEGORY_FETCHED = 'PRODUCT_CATEGORY_FETCHED';

export const SET_PRODUCTS = 'SET_PRODUCTS';
export const PRODUCT_FETCHED = 'PRODUCT_FETCHED';

export const SET_PRODUCT_ITEMS = 'SET_PRODUCT_ITEMS';
export const PRODUCT_ITEM_FETCHED = 'PRODUCT_ITEM_FETCHED';

// ADMIN 

export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const PERMISSION_FETCHED = 'PERMISSION_FETCHED';
export const USERS_SEARCHED = 'USERS_SEARCHED';

// Oculus

export const SET_PAGEVIEWS = 'SET_PAGEVIEWS';
export const PAGEVIEW_FETCHED = 'PAGEVIEW_FETCHED';

// Tasks

export const SET_TASKS = 'SET_TASKS';
export const TASK_FETCHED = 'TASK_FETCHED';

export const SET_RINGS = 'SET_RINGS';
export const RING_FETCHED = 'RING_FETCHED';

export const MESSAGES_FETCHED = 'MESSAGES_FETCHED';
export const MESSAGE_FETCHED = 'MESSAGE_FETCHED';
export const MESSAGE_CREATED = 'MESSAGE_CREATED';

export const NOTIFICATIONS_FETCHED = 'NOTIFICATIONS_FETCHED';
export const NOTIFICATION_FETCHED = 'NOTIFICATION_FETCHED';
export const NOTIFICATION_CREATED = 'NOTIFICATION_CREATED';





// export const SET_PEOPLE_SELECTABLES = 'SET_PEOPLE_SELECTABLES';
// export const SET_EVENT_HORSES_SELECTABLES = 'SET_EVENT_HORSES_SELECTABLES';


export const PERSON_FETCHED = 'PERSON_FETCHED';
export const SET_PEOPLE = 'SET_PEOPLE';

// Old style
export const FETCH_EVENTS = 'FETCH_EVENTS';

// ADMIN

export const ADMIN_WORK_ORDER_FETCHED = 'ADMIN_WORK_ORDER_FETCHED';
export const SET_ADMIN_WORK_ORDERS = 'SET_ADMIN_WORK_ORDERS';

export const USER_FETCHED = 'USER_FETCHED';
export const SET_USERS = 'SET_USERS';
export const SEARCH_CLEAR_USERS = 'SEARCH_CLEAR_USERS';
export const SEARCH_CLEAR_USER = 'SEARCH_CLEAR_USER';
export const SEARCH_USER_FETCHED = 'SEARCH_USER_FETCHED';
export const SEARCH_SET_USERS = 'SEARCH_SET_USERS';

export const SET_LOG_BOOKS = 'SET_LOG_BOOKS';
export const LOG_BOOK_UPDATED = 'LOG_BOOK_UPDATED';
export const LOG_BOOK_FETCHED = 'LOG_BOOK_FETCHED';
export const ADMIN_LOG_BOOK_FETCHED = 'ADMIN_LOG_BOOK_FETCHED';
export const SET_ADMIN_LOG_BOOKS = 'SET_ADMIN_LOG_BOOKS';
export const LOG_BOOK_ENTRIES_FETCHED = 'LOG_BOOK_ENTRIES_FETCHED';
export const LOG_BOOK_ENTRY_FETCHED = 'LOG_BOOK_ENTRY_FETCHED';
