/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Image } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
/* eslint-enable no-unused-vars */

export default function UserCard({ user }) {
  function renderImgTag() {
    if (user && user.gender) {
      if (user.gender === 'male') {
        return (
          <Image src="/images/img_avatar.png" alt="Avatar" rounded responsive />
        );
      } else if (user.gender === 'female') {
        return (
          <Image
            src="/images/img_avatar2.png"
            alt="Avatar"
            rounded
            responsive
          />
        );
      }
    }
    return (
      <Image src="/images/unknown_user.png" alt="Avatar" rounded responsive />
    );
  }

  return (
    <Col xs={12} md={6}>
      <div className="card">
        <LinkContainer to={'/users/edit/' + user._id}>
          {renderImgTag()}
        </LinkContainer>
        <div className="container">
          <h4>
            <b>{user.displayName}</b>
          </h4>
        </div>
      </div>
    </Col>
  );
}

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
};
